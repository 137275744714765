import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AddGroupToWaterBudgetDialogController extends BaseController {
  public dialogGroups = [] as fuse.waterBudgetGroupDto[];
  public isSelectAllGroups = false;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    dialogGroups: fuse.waterBudgetGroupDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.dialogGroups = dialogGroups;
  }

  public addGroups() {
    this._mdDialog.hide({ dialogGroups: this.dialogGroups });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public isAnyGroupSelected() {
    if (this.dialogGroups != null) {
      return this.dialogGroups.some((a) => a.isSelected == true);
    } else {
      return false;
    }
  }

  public selectGroup() {
    if (this.dialogGroups.some((a) => a.isSelected == false)) {
      this.isSelectAllGroups = false;
    } else {
      this.isSelectAllGroups = true;
    }
  }

  public selectAllGroups() {
    if (this.isSelectAllGroups) {
      this.dialogGroups.forEach((group) => {
        group.isSelected = true;
      });
    } else {
      this.dialogGroups.forEach((group) => {
        group.isSelected = false;
      });
    }
  }
}

angular.module('app.water').controller('AddGroupToWaterBudgetDialogController', AddGroupToWaterBudgetDialogController);
