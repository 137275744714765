import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { WaterConstants, IWaterBudgetSiteSetting } from '../WaterConstants';
import { BaseController } from 'src/app/base.controller';

export class WaterBudgetUpdateMultiSitesDialogController extends BaseController {
  public waterBudgetSiteSettings: IWaterBudgetSiteSetting[];

  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.waterBudgetSiteSettings = [
      {
        key: WaterConstants.systemCoefficiencyKey,
        name: WaterConstants.systemCoefficiencyKey,
        valueType: 'number',
        minValue: 0,
        maxValue: 100,
      },
      {
        key: WaterConstants.usefulRainfallKey,
        name: WaterConstants.usefulRainfallKey,
        valueType: 'number',
        minValue: 0,
        maxValue: 100,
      },
    ] as IWaterBudgetSiteSetting[];
  }

  public updateSettings() {
    this._mdDialog.hide({ waterBudgetSiteSettings: this.waterBudgetSiteSettings });
  }

  public closeDialog() {
    if (this.isItemChecked()) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public isItemChecked(): boolean {
    if (this.waterBudgetSiteSettings.some((a) => a.checked)) return true;
    return false;
  }
}

angular
  .module('app.water')
  .controller('WaterBudgetUpdateMultiSitesDialogController', WaterBudgetUpdateMultiSitesDialogController);
