import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { WaterBudgetHelpDialogController } from '../waterBudgetHelp-dialog.controller';
import { unitSizes } from '@common/enums';

class WaterBudgetMonthListTableComponent implements angular.IComponentOptions {
  bindings = {
    monthList: '<',
    totalArea: '<',
    waterUnitType: '<',
    monthListChanged: '&',
  };
  controller = WaterBudgetMonthListTableController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/water/waterbudget/components/water-budget-month-list-table.html';
}

class WaterBudgetMonthListTableController {
  private _mdDialog: angular.material.IDialogService;
  private _unitOfMeasureService: UnitOfMeasureService;
  private _languageService: LanguageService;
  public monthList: fuse.waterBudgetMonthDto[];
  private totalArea: number;
  private monthListChanged: Function;
  public volumeLargeUnit: uomUnit;
  public volumeAreaLargeUnit: uomUnit;

  constructor(
    $mdDialog: angular.material.IDialogService,
    UnitOfMeasureService: UnitOfMeasureService,
    LanguageService: LanguageService,
  ) {
    this._mdDialog = $mdDialog;
    this._unitOfMeasureService = UnitOfMeasureService;
    this._languageService = LanguageService;
    this.volumeLargeUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.large);
    this.volumeAreaLargeUnit = this._unitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
  }

  public changeMonthCapValue(monthValue: fuse.waterBudgetMonthDto): void {
    if (monthValue.monthCapWater == null) {
      monthValue.monthCapWaterPerHa = null;
    } else if (monthValue.monthCapWater) {
      monthValue.monthCapWaterPerHa = monthValue.monthCapWater / this.totalArea;
    }
    this.monthListChanged({ monthList: this.monthList });
  }

  public changeMonthCapPerHaValue(monthValue: fuse.waterBudgetMonthDto) {
    if (monthValue.monthCapWaterPerHa == null) {
      monthValue.monthCapWater = null;
    } else if (monthValue.monthCapWaterPerHa) {
      monthValue.monthCapWater = monthValue.monthCapWaterPerHa * this.totalArea;
    }
    this.monthListChanged({ monthList: this.monthList });
  }

  public openWaterBudgetHelpDialog(key: string) {
    this._mdDialog.show({
      controller: WaterBudgetHelpDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/waterbudget/waterBudgetHelp-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        key: key,
      },
    });
  }

  public textConditionColor(desiredValue: number, budgetedValue: number): string {
    if (desiredValue == null || isNaN(desiredValue) || desiredValue == 0) return null;
    else if (budgetedValue / desiredValue <= 0.9) return 'red';
    else if (budgetedValue / desiredValue < 0.99) return 'orange';
    else return 'rgba(0, 0, 0, 0.7)';
  }

  public alertConditionDisplay(desiredValue: number, budgetedValue: number): boolean {
    if (desiredValue == null || isNaN(desiredValue) || desiredValue == 0 || budgetedValue == null || isNaN(budgetedValue))
      return false;
    else if (budgetedValue / desiredValue <= 0.9) return true;
    else if (budgetedValue / desiredValue < 0.99) return true;
    else if (budgetedValue / desiredValue < 1.05) return false;
    else return true;
  }

  public monthCapAlertConditionDisplay(month: fuse.waterBudgetMonthDto): boolean {
    if (month.waterKLOverride == null || isNaN(month.waterKLOverride) || month.waterKLBudget == 0) {
      month.monthToolTip = '';
    } else if (month.waterKLOverride / month.waterKLBudget <= 0.9) {
      month.monthToolTip = this._languageService.instant('WATER.BUDGET.WARNING.WATER_MUCH_LESS');
    } else if (month.waterKLOverride / month.waterKLBudget <= 0.99) {
      month.monthToolTip = this._languageService.instant('WATER.BUDGET.WARNING.WATER_LESS');
    } else if (month.waterKLOverride / month.waterKLBudget > 1.05) {
      month.monthToolTip = this._languageService.instant('WATER.BUDGET.WARNING.WATER_MORE');
    } else {
      month.monthToolTip = '';
    }
    if (month.monthCapWater == null || isNaN(month.monthCapWater)) {
      month.monthToolTip1 = '';
    } else if (month.waterKLOverride / month.monthCapWater > 1.05) {
      month.monthToolTip1 = this._languageService.instant('WATER.BUDGET.WARNING.EXCEED_CAP');
    } else {
      month.monthToolTip1 = '';
    }
    if (month.monthToolTip == '' && month.monthToolTip1 == '') return false;
    else return true;
  }
}

angular.module('app.water').component('waterBudgetMonthListTable', new WaterBudgetMonthListTableComponent());
