import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { BaseController } from 'src/app/base.controller';

export class CloneWaterBudgetDialogController extends BaseController {
  public waterBudgetName: string;
  public startYear: number;
  public startYears = [] as number[];
  public waterBudgetNameExists = false;
  public isCopyWeather = true;
  public isCopyCrop = true;
  public isCopySites = true;

  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  private waterBudgets = [] as fuse.waterBudgetDto[];
  private waterBudget: fuse.waterBudgetDto;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    waterBudgets: fuse.waterBudgetDto[],
    waterBudget: fuse.waterBudgetDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.waterBudgets = waterBudgets;
    this.waterBudget = waterBudget;
    this.startYear = waterBudget.startDate.getFullYear();
    this.waterBudgetName = waterBudget.name;

    let i = 0;
    let budgetName = this.waterBudget.name;

    while (this.waterBudgets.some((a) => a.name == budgetName)) {
      i++;
      budgetName = this.waterBudget.name + ' - ' + i;
    }

    this.waterBudgetName = budgetName;
    const minYear = this.startYear - 2;
    const maxyear = minYear + 20;
    for (let year = minYear; year < maxyear; year++) {
      this.startYears.push(year);
    }
  }

  public cloneWaterBudget() {
    const newBudget = {
      name: this.waterBudgetName,
      status: this.waterBudget.status,
      id: this.waterBudget.id, //this will be overwritten later
      defaultKLPerHa: this.waterBudget.defaultKLPerHa,
      systemEfficiency: this.waterBudget.systemEfficiency,
      usefulRainfall: this.waterBudget.usefulRainfall,
      weatherSource: this.waterBudget.weatherSource,
      waterBudgetSites: [],
      waterBudgetMonths: [],
    } as fuse.waterBudgetDto;
    const yearOffset = this.startYear - this.waterBudget.startDate.getFullYear();
    newBudget.startDate = this.waterBudget.startDate.clone().addYears(yearOffset);
    newBudget.endDate = this.waterBudget.endDate.clone().addYears(yearOffset);
    if (this.isCopyWeather) {
      newBudget.etoStationId = this.waterBudget.etoStationId;
      newBudget.etoStationName = this.waterBudget.etoStationName;
      newBudget.rainStationId = this.waterBudget.rainStationId;
      newBudget.rainStationName = this.waterBudget.rainStationName;
    }
    if (this.isCopyCrop) {
      newBudget.cropId = this.waterBudget.cropId;
      newBudget.cropName = this.waterBudget.cropName;
    }
    if (this.isCopySites) {
      this.waterBudget.waterBudgetSites.forEach((site) => {
        const siteProfile = {
          siteId: site.siteId,
          systemEfficiency: site.systemEfficiency,
          rainfallAdjustment: site.rainfallAdjustment,
          totalWaterKLBudgetPerHa: site.totalWaterKLBudgetPerHa,
        } as fuse.waterBudgetSiteDto;
        newBudget.waterBudgetSites.push(siteProfile);
      });
    }
    this.waterBudget.waterBudgetMonths.forEach((month) => {
      const monthProfile = {} as fuse.waterBudgetMonthDto;
      monthProfile.dateTime = month.dateTime.clone().addYears(yearOffset);
      monthProfile.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(monthProfile.dateTime);

      if (this.isCopyWeather) {
        monthProfile.expectedEtoDailyPercent = month.expectedEtoDailyPercent;
        monthProfile.expectedRainMonthlyPercent = month.expectedRainMonthlyPercent;
      } else {
        monthProfile.expectedEtoDailyPercent = 100;
        monthProfile.expectedRainMonthlyPercent = 100;
      }

      if (this.isCopyCrop) {
        monthProfile.desiredCropCoefficient = month.desiredCropCoefficient;
      }

      newBudget.waterBudgetMonths.push(monthProfile);
    });
    this._mdDialog.hide({ newBudget: newBudget });
  }

  public closeDialog() {
    if (this.scope['cloneWaterBudgetForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public checkWaterBudgetInfo() {
    if (this.waterBudgetName) {
      if (this.waterBudgets.some((a) => a.name.toLowerCase() == this.waterBudgetName.toLowerCase())) {
        this.waterBudgetNameExists = true;
      } else {
        this.waterBudgetNameExists = false;
      }
    } else {
      this.waterBudgetNameExists = false;
    }
  }
}

angular.module('app.water').controller('CloneWaterBudgetDialogController', CloneWaterBudgetDialogController);
