import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { WaterConstants } from '../WaterConstants';
import { WaterBudgetAdjustHelpDialogController } from './waterBudgetAdjustHelp-dialog.controller';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class ChangeBudgetTotalIrrigationDialogController extends BaseController {
  public waterBudgetAllAdjustActions = angular.copy(WaterConstants.waterBudgetAllAdjustActions);

  public adjustAction: number;
  public totalWaterKLOverride: number;
  public totalWaterKLOverridePerHa: number;
  public percentOfBudgetValue: number;
  public percentOfOverrideValue: number;
  private totalMonths: number;
  public volumeAreaLargeUnit: uomUnit;
  public volumeLargeUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  private _dialogSites = [] as fuse.waterBudgetSiteDto[];
  private _budget: fuse.waterBudgetDataSourceDto;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    budget: fuse.waterBudgetDataSourceDto,
    dialogSites: fuse.waterBudgetSiteDto[],
    waterUnitType: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.volumeAreaLargeUnit = UnitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
    this.volumeLargeUnit = UnitOfMeasureService.getUnits('Volume', unitSizes.large);
    this.waterBudgetAllAdjustActions.forEach((item) => {
      if (item.name == 'VolumeAreaUnit') {
        item.description = this.volumeAreaLargeUnit.name;
      } else {
        item.description = this._languageService.instant(item.description);
      }
    });

    this._budget = budget;
    this._dialogSites = dialogSites;
    this.adjustAction = waterUnitType;
    this.totalWaterKLOverride = this._budget.totalWaterKLOverride;
    this.totalWaterKLOverridePerHa = this._budget.totalWaterKLOverridePerHa;
    this.totalMonths = this._budget.monthList.length;
  }

  public submit() {
    if (this.adjustAction == 0) {
      if (this._budget.totalWaterKLBudgetPerHa != 0) {
        const proportion = this.totalWaterKLOverridePerHa / this._budget.totalWaterKLBudgetPerHa;
        this._dialogSites.forEach((site) => {
          site.monthList.forEach((siteMonth) => {
            siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa * proportion;
            siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
          });
        });
      } else {
        this._budget.totalWaterKLOverridePerHa = this.totalWaterKLOverridePerHa;
        const waterPerHaPerMonth = this._budget.totalWaterKLOverridePerHa / this.totalMonths;
        this._dialogSites.forEach((site) => {
          site.monthList.forEach((siteMonth) => {
            siteMonth.water_KL_Override_PerHa = waterPerHaPerMonth;
            siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
          });
        });
      }
    } else if (this.adjustAction == 1) {
      if (this._budget.totalWaterKLBudget != 0) {
        const proportion = this.totalWaterKLOverride / this._budget.totalWaterKLBudget;
        this._dialogSites.forEach((site) => {
          site.monthList.forEach((siteMonth) => {
            siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa * proportion;
            siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
          });
        });
      } else {
        this._budget.totalWaterKLOverride = this.totalWaterKLOverride;
        this._budget.totalWaterKLOverridePerHa = this._budget.totalWaterKLOverride / this._budget.totalIrrigationArea;
        const waterPerHaPerMonth = this._budget.totalWaterKLOverridePerHa / this.totalMonths;
        this._dialogSites.forEach((site) => {
          site.monthList.forEach((siteMonth) => {
            siteMonth.water_KL_Override_PerHa = waterPerHaPerMonth;
            siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
          });
        });
      }
    } else if (this.adjustAction == 2) {
      this._dialogSites.forEach((site) => {
        site.monthList.forEach((siteMonth) => {
          siteMonth.water_KL_Override = siteMonth.water_KL_Budget;
          siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa;
        });
      });
    } else if (this.adjustAction == 3) {
      //percent of desired value
      this._dialogSites.forEach((site) => {
        site.monthList.forEach((siteMonth) => {
          siteMonth.water_KL_Override = (siteMonth.water_KL_Budget * this.percentOfBudgetValue) / 100;
          siteMonth.water_KL_Override_PerHa = (siteMonth.water_KL_Budget_PerHa * this.percentOfBudgetValue) / 100;
        });
      });
    } else if (this.adjustAction == 4) {
      //percent of override value
      this._dialogSites.forEach((site) => {
        site.monthList.forEach((siteMonth) => {
          siteMonth.water_KL_Override = (siteMonth.water_KL_Override * this.percentOfOverrideValue) / 100;
          siteMonth.water_KL_Override_PerHa = (siteMonth.water_KL_Override_PerHa * this.percentOfOverrideValue) / 100;
        });
      });
    }
    this._mdDialog.hide({ dialogSites: this._dialogSites });
  }

  public closeDialog() {
    if (this.scope['changeBudgetTotalIrrigationForm'].$pristine == false) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public openBudgetAdjustHelpDialog(actionId: number) {
    this._mdDialog
      .show({
        controller: WaterBudgetAdjustHelpDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/waterBudgetAdjustHelp-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        multiple: true,
        locals: {
          key: 'all',
          actionId: actionId,
        },
      })
      .then(() => {});
  }
}

angular
  .module('app.water')
  .controller('ChangeBudgetTotalIrrigationDialogController', ChangeBudgetTotalIrrigationDialogController);
