import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { StatusEnum } from '@indicina/swan-shared/enums/StatusEnum';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AddWaterBudgetDialogController extends BaseController {
  public waterBudgetName: string;
  public startDate: Date;
  public endDate: Date;
  public waterBudgetNameExists = false;
  public startDateMinimumDate: Date;
  public startDateMaximumDate: Date;
  public endDateMinimumDate: Date;
  public endDateMaximumDate: Date;

  private _mdDialog: angular.material.IDialogService;

  private waterBudgets = [] as fuse.waterBudgetDto[];
  private calendarMinDate: Date;
  private calendarMaxDate: Date;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    waterBudgets: fuse.waterBudgetDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.waterBudgets = waterBudgets;
    this.scope['datePickerLocale'] =  DateUtils.Locale.AngularJS.DatePicker.monthAndYearShort();

    const today = new Date();
    this.calendarMinDate = new Date(today.getFullYear() - 2, today.getMonth(), 1);
    this.calendarMaxDate = new Date(today.getFullYear() + 10, today.getMonth() + 1, 0);
    this.startDateMinimumDate = this.calendarMinDate.clone();
    this.endDateMinimumDate = this.calendarMinDate.clone();
    this.startDateMaximumDate = this.calendarMaxDate.clone();
    this.endDateMaximumDate = this.calendarMaxDate.clone();
  }

  public addWaterBudget() {
    const newBudget = {
      name: this.waterBudgetName,
      status: StatusEnum.Active,
      startDate: this.startDate,
      endDate: this.endDate,
      weatherSource: 'W',
    } as fuse.waterBudgetDto;
    this._mdDialog.hide({ newBudget: newBudget });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public checkWaterBudgetInfo() {
    if (this.waterBudgetName) {
      if (this.waterBudgets.some((a) => a.name.toLowerCase() == this.waterBudgetName.toLowerCase())) {
        this.waterBudgetNameExists = true;
      } else {
        this.waterBudgetNameExists = false;
      }
    } else {
      this.waterBudgetNameExists = false;
    }
    if (this.startDate) {
      this.endDateMinimumDate = this.startDate.clone();
      this.endDateMaximumDate = this.startDate.clone().addYears(1).addDays(-1);
      if (this.endDateMaximumDate > this.calendarMaxDate) {
        this.endDateMaximumDate = this.calendarMaxDate;
      }
    }
    if (this.endDate && this.endDate.getDate() == 1) {
      //set to last date of the month
      this.endDate.addMonths(1).addDays(-1);
      this.startDateMaximumDate = this.endDate.clone();
      this.startDateMinimumDate = this.endDate.clone().addYears(-1).addDays(1);
      if (this.startDateMinimumDate < this.calendarMinDate) {
        this.startDateMinimumDate = this.calendarMinDate.clone();
      }
    }
  }
}

angular.module('app.water').controller('AddWaterBudgetDialogController', AddWaterBudgetDialogController);
