import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { AllocationController } from './allocation/allocation.controller';
import { AllocationsController } from './allocations/allocations.controller';
import { IrrigationControllersController } from './irrigation-controllers/irrigation-controllers.controller';
import { IrrigationControllerController } from './irrigation-controller/irrigation-controller.controller';
import { IrrigationPlansController } from './irrigation-plans/irrigation-plans.controller';
import { IrrigationPlanController } from './irrigation-plan/irrigation-plan.controller';
import { IrrigationOverrideController } from './irrigation-override/irrigation-override.controller';
import { WaterBudgetsController } from './waterbudgets/waterBudgets.controller';
import { WaterBudgetController } from './waterbudget/waterBudget.controller';

const waterConfig = (
  $stateProvider: angular.ui.IStateProvider,
) => {
  $stateProvider.state('app.water', {
    abstract: true,
    url: '/water',
  });

  $stateProvider.state('app.water.allocations', {
    url: '/allocations',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/allocations/allocations.html',
        controller: AllocationsController,
        controllerAs: 'vm',
      },
    },
  });

  $stateProvider.state('app.water.allocations.detail', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/allocation/allocation.html',
        controller: AllocationController,
        controllerAs: 'vm',
      },
    },
  });

  $stateProvider.state('app.water.irrigation-controllers', {
    url: '/irrigationcontrollers',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/irrigation-controllers/irrigation-controllers.html',
        controller: IrrigationControllersController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.IrrigationControllerView },
    resolve: {},
  });

  $stateProvider.state('app.water.irrigation-controllers.detail', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/irrigation-controller/irrigation-controller.html',
        controller: IrrigationControllerController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.IrrigationControllerView },
    resolve: {},
  });

  $stateProvider.state('app.water.irrigation-override', {
    url: '/irrigationoverride',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/irrigation-override/irrigation-override.html',
        controller: IrrigationOverrideController,
        controllerAs: 'vm',
      },
    },
  });

  $stateProvider.state('app.water.irrigation-plans', {
    url: '/irrigationplans',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/irrigation-plans/irrigation-plans.html',
        controller: IrrigationPlansController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.IrrigationPlanView },
    resolve: {},
  });

  $stateProvider.state('app.water.irrigation-plans.detail', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/irrigation-plan/irrigation-plan.html',
        controller: IrrigationPlanController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.IrrigationPlanView },
    resolve: {},
  });

  $stateProvider.state('app.water.budgets', {
    url: '/waterbudgets',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/waterbudgets/waterBudgets.html',
        controller: WaterBudgetsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.WaterBudgetView },
    resolve: {},
  });

  $stateProvider.state('app.water.budgets.detail', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/water/waterbudget/waterBudget.html',
        controller: WaterBudgetController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.WaterBudgetView },
    resolve: {},
  });

  /*
  $stateProvider.state('app.water.licences', {
    url: '/water-licences',
    views: {
        'content@app': {
            templateUrl: 'src/app/pages/water/water-licences/water-licences.html',
            controller: WaterLicencesController,
            controllerAs: 'vm'
        }
    },
    data: { permission: ApplicationPrivileges.WaterLicenceView }
  });

  $stateProvider.state('app.water.licences.detail', {
    url: '/:id',
    views: {
        'content@app': {
            templateUrl: 'src/app/pages/water/water-licence/water-licence.html',
            controller: WaterLicenceDetailController,
            controllerAs: 'vm'
        }
    },
    data: { permission: ApplicationPrivileges.WaterLicenceView }
  });
  */
}

angular.module('app.water').config(['$stateProvider', waterConfig]);
