// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#water-budget-projection-chart {
  position: absolute;
  width: 100%;
  height: 230px;
  min-width: 300px;
}
#water-budget-projection-chart .amcharts-chart-div {
  left: 230px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/waterbudget/components/water-budget-projection-chart.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;AACJ;AAAI;EACI,sBAAA;AAER","sourcesContent":["#water-budget-projection-chart {\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 230px;\r\n    min-width: 300px;\r\n    .amcharts-chart-div {\r\n        left: 230px !important;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
