import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class AddSiteToWaterBudgetDialogController extends BaseController {
  public dialogSites = [] as fuse.waterBudgetSiteDto[];
  public isSelectAllSites = false;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    dialogSites: fuse.waterBudgetSiteDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.dialogSites = dialogSites;
  }

  public addSites() {
    this._mdDialog.hide({ dialogSites: this.dialogSites });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public isAnySiteSelected() {
    if (this.dialogSites != null) {
      return this.dialogSites.some((a) => a.isInWaterBudget == true);
    } else {
      return false;
    }
  }

  public selectSite(site: fuse.waterBudgetSiteDto) {
    if (this.dialogSites.some((a) => a.isInWaterBudget == false)) {
      this.isSelectAllSites = false;
    } else {
      this.isSelectAllSites = true;
    }
  }

  public selectAllSites() {
    if (this.isSelectAllSites) {
      this.dialogSites.forEach((site) => {
        site.isInWaterBudget = true;
      });
    } else {
      this.dialogSites.forEach((site) => {
        site.isInWaterBudget = false;
      });
    }
  }
}

angular.module('app.water').controller('AddSiteToWaterBudgetDialogController', AddSiteToWaterBudgetDialogController);
