import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { WaterConstants } from '../WaterConstants';
import { uomUnit } from '@services/unit-of-measure.service';
import { IIdValueItem } from '@common/models/interfaces';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class ChangeWeatherStationDialogController extends BaseController {
  public weatherStationYears = WaterConstants.weatherStationYears;
  public weatherSources = WaterConstants.weatherSources;

  public filteredWeatherStations = [] as fuse.waterBudgetWeatherStationDto[];
  public maxStationDistance: number;
  public minStationYears = null as number;
  public weatherSource: string;

  public waterBudgetDataSource: fuse.waterBudgetDataSourceDto;
  public isRenderingPage = false;
  public searchKeyword = '';
  public isIncludeForecast = false;
  public lengthLargeUnit: uomUnit;
  public fluidDepthUnit: string;
  public wsDistances: IIdValueItem[];
  public milesDisplayValues = WaterConstants.milesDisplayValues;
  public metricDisplayValues = WaterConstants.metricDisplayValues;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  private weatherStations = [] as fuse.waterBudgetWeatherStationDto[];
  private latitude: number;
  private longitude: number;
  private waterBudget: fuse.waterBudgetDto;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    UnitOfMeasureService: UnitOfMeasureService,
    PermissionService: PermissionService,
    latitude: number,
    longitude: number,
    waterBudget: fuse.waterBudgetDto,
    waterBudgetDataSource: fuse.waterBudgetDataSourceDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.lengthLargeUnit = UnitOfMeasureService.getUnits('Length', unitSizes.large);
    this.fluidDepthUnit = UnitOfMeasureService.getUnitLabel('Fluid Depth', unitSizes.normal);

    this.wsDistances = [];

    if (this.lengthLargeUnit.name === 'mi') {
      this.milesDisplayValues.forEach((val) => this.wsDistances.push({ id: val, value: this.lengthLargeUnit.toBase(val) }));
    } else if (this.lengthLargeUnit.name === 'km') {
      this.metricDisplayValues.forEach((val) => this.wsDistances.push({ id: val, value: val }));
    } else {
      this.metricDisplayValues.forEach((val) => this.wsDistances.push({ id: this.lengthLargeUnit.fromBase(val), value: val }));
    }

    this.maxStationDistance = this.wsDistances[0].value;
    this.latitude = latitude;
    this.longitude = longitude;
    this.waterBudget = angular.copy(waterBudget);
    this.waterBudgetDataSource = angular.copy(waterBudgetDataSource);
    this.weatherSource = this.waterBudget.weatherSource == null ? 'W' : this.waterBudget.weatherSource;
  }

  $onInit() {
    if (this.weatherSource == 'W') {
      this.refreshWeatherStations();
    }
  }

  public refreshWeatherStations() {
    this.isRenderingPage = true;
    const params = {
      accountId: this.accountId,
      latitude: this.latitude,
      longitude: this.longitude,
      maxStationDistance: Math.round(this.maxStationDistance),
      budgetStartMonth: this.waterBudget.startDate.getMonth() + 1,
      budgetEndMonth: this.waterBudget.endDate.getMonth() + 1,
    };
    this._http.get(CommonHelper.getApiUrl('water/GetWaterBudgetWeatherStations'), { params: params }).then(
      (response) => {
        this.isRenderingPage = false;
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.weatherStations = response.data as fuse.waterBudgetWeatherStationDto[];
          if (!this.weatherStations.length) {
            this._languageService.warning('WATER.BUDGET.NO_WEATHER');
          } else {
            if (this.waterBudget.etoStationId != null) {
              const etoStation = this.weatherStations.find((a) => a.id == this.waterBudget.etoStationId);
              if (etoStation != null) {
                etoStation.isSelectedForEto = true;
                etoStation.isAlwaysDisplay = true;
              }
            }
            if (this.waterBudget.rainStationId != null) {
              const rainStation = this.weatherStations.find((a) => a.id == this.waterBudget.rainStationId);
              if (rainStation != null) {
                rainStation.isSelectedForRainfall = true;
                rainStation.isAlwaysDisplay = true;
              }
            }
            if (this.waterBudget.weatherSource != 'M') {
              this.waterBudgetDataSource.monthList.forEach((month) => {
                month.manualEto = null;
                month.manualRain = null;
              });
            }
          }
          this.filterWeatherStations();
        }
      },
      () => {
        this.isRenderingPage = false;
      },
    );
  }

  public selectEtoWeatherStation(station: fuse.waterBudgetWeatherStationDto) {
    this.weatherStations.forEach((station) => {
      station.isSelectedForEto = false;
    });
    station.isSelectedForEto = true;
  }

  public selectRainfallWeatherStation(station: fuse.waterBudgetWeatherStationDto) {
    this.weatherStations.forEach((station) => {
      station.isSelectedForRainfall = false;
    });
    station.isSelectedForRainfall = true;
  }

  public updateWeatherStations() {
    this.waterBudget.weatherSource = this.weatherSource;
    if (this.weatherSource == 'W') {
      const etoStation = this.weatherStations.find((a) => a.isSelectedForEto == true);
      this.waterBudget.etoStationId = etoStation.id;
      this.waterBudget.etoStationName = etoStation.name;
      const rainStation = this.weatherStations.find((a) => a.isSelectedForRainfall == true);
      this.waterBudget.rainStationId = rainStation.id;
      this.waterBudget.rainStationName = rainStation.name;
      this.waterBudgetDataSource.monthList.forEach((month) => {
        month.manualEto = null;
        month.manualRain = null;
      });
    } else if (this.weatherSource == 'M') {
      this.waterBudget.etoStationId = null;
      this.waterBudget.etoStationName = this._languageService.instant('COMMON.MANUAL_ENTRY');
      this.waterBudget.rainStationId = null;
      this.waterBudget.rainStationName = this._languageService.instant('COMMON.MANUAL_ENTRY');
    } else if (this.weatherSource == 'I') {
      this.waterBudget.etoStationId = null;
      this.waterBudget.etoStationName = this._languageService.instant('WATER.BUDGET.IGNORE_WEATHER');
      this.waterBudget.rainStationId = null;
      this.waterBudget.rainStationName = this._languageService.instant('WATER.BUDGET.IGNORE_WEATHER');
      this.waterBudgetDataSource.monthList.forEach((month) => {
        month.manualEto = null;
        month.manualRain = null;
      });
    }
    this._mdDialog.hide({ waterBudget: this.waterBudget, waterBudgetDataSource: this.waterBudgetDataSource });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public isWeatherStationsSelected(): boolean {
    if (this.weatherStations) {
      const isEtoStationSelected = this.weatherStations.some((a) => a.isSelectedForEto == true);
      const isRainStationSelected = this.weatherStations.some((a) => a.isSelectedForRainfall == true);
      return isEtoStationSelected && isRainStationSelected;
    } else {
      return false;
    }
  }

  public manualInputFormValid(): boolean {
    if (this.weatherSource == 'M') {
      if (
        this.waterBudgetDataSource.monthList.some((a) => a.manualEto == null) ||
        this.waterBudgetDataSource.monthList.some((a) => a.manualRain == null)
      ) {
        return false;
      }
    }
    return true;
  }

  public filterWeatherStations(): void {
    this.filteredWeatherStations = this.weatherStations;
    if (this.searchKeyword != null) {
      this.filteredWeatherStations = this.filteredWeatherStations.filter(
        (a) => a.name.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) > -1,
      );
    }
    if (this.minStationYears) {
      this.filteredWeatherStations = this.filteredWeatherStations.filter(
        (a) => a.yearsEto >= this.minStationYears && a.yearsRain >= this.minStationYears,
      );
    }
    if (this.isIncludeForecast == false) {
      this.filteredWeatherStations = this.filteredWeatherStations.filter((a) => a.isAlwaysDisplay || a.type == 'Actual');
    }
  }

  public weatherSourceChanged(): void {
    if (this.weatherSource == 'W') {
      if (!this.weatherStations?.length) {
        this.refreshWeatherStations();
      }
    }
  }
}

angular.module('app.water').controller('ChangeWeatherStationDialogController', ChangeWeatherStationDialogController);
