import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { TableControl } from '@common/helpers/TableControl';
import { ApiService } from '@services/api.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { WaterService } from '@services/water.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { AddWaterBudgetDialogController } from './addwaterbudget-dialog.controller';
import { CloneWaterBudgetDialogController } from './clonewaterbudget-dialog.controller';
import { SweepBudgetFromSitesDialogController } from './sweepBudgetFromSites-dialog.controller';
import { WaterConstants } from '../WaterConstants';
import { BaseController } from 'src/app/base.controller';

export class WaterBudgetsController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _apiService: ApiService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _waterService: WaterService;

  public waterBudgetStatuses = WaterConstants.waterBudgetStatuses;

  public iActive = 0;
  public iArchived = 0;
  public showActive = true;
  public showArchived = false;
  public nameFilter = '';
  public cropFilter = '';
  private waterBudgets = [] as fuse.waterBudgetDto[];
  public filteredBudgets = [] as fuse.waterBudgetDto[];
  public tableControl = new TableControl();
  public volumeHugeUnit: uomUnit;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    ApiService: ApiService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    WaterService: WaterService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._state = $state;
    this._apiService = ApiService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._dayNumberService = DayNumberService;
    this._waterService = WaterService;

    this.volumeHugeUnit = UnitOfMeasureService.getUnits('Volume', unitSizes.huge);

    if (WaterService.getKeepFilter() == true) {
      this.nameFilter = WaterService.getNameFilter();
      this.cropFilter = WaterService.getCropFilter();
      this.showActive = WaterService.getActiveState();
      this.showArchived = WaterService.getArchivedState();
      WaterService.setKeepFilter(false);
    } else {
      WaterService.setNameFilter(this.nameFilter);
      WaterService.setCropFilter(this.cropFilter);
      WaterService.setActiveState(this.showActive);
      WaterService.setArchivedState(this.showArchived);
    }
  }

  $onInit() {
    this.waterBudgets = [];

    LocalStorageUtils.updateContextData((context) => {
      context.budgetId = null;
    });

    this._localStorageService.remove('waterbudget');

    this.setWatches();
    this.getWaterBudgets();
  }

  private setWatches() {
    this.scope.$watchGroup(['vm.nameFilter', 'vm.cropFilter'], () => {
      if (this.waterBudgets.length) {
        this.filterBudgets();
      }
    });
  }

  public getBudgetStatusIcon(status: string) {
    if (status === 'Active') {
      return 'icon-checkbox-marked-circle swanGreen';
    }
    if (status === 'Archived') {
      return 'icon-cancel red-500-fg';
    }
    return 'icon-checkbox-marked-circle swanGreen';
  }

  private getWaterBudgets() {
    const params = { accountId: this.accountId };
    this._http.get(CommonHelper.getApiUrl('water/GetWaterBudgets'), { params: params }).then((response) => {
      if (response.data == null) {
        this._languageService.whoops();
      } else {
        this.waterBudgets = response.data as fuse.waterBudgetDto[];
        this.waterBudgets.forEach((waterBudget) => {
          waterBudget.startDate = this._dayNumberService.convertDayNumberToDate(waterBudget.startDayNumber);
          waterBudget.endDate = this._dayNumberService.convertDayNumberToDate(waterBudget.endDayNumber);
          waterBudget.waterBudgetMonths.forEach((month) => {
            month.dateTime = this._dayNumberService.convertDayNumberToDate(month.dayNumber);
            month.monthValue = month.dateTime.getMonth();
          });
          if (waterBudget.status == 'Archived') this.iArchived++;
          else this.iActive++;
        });
        this.filterBudgets();
      }
    });
  }

  private filterBudgets() {
    this._waterService.setNameFilter(this.nameFilter);
    this._waterService.setCropFilter(this.cropFilter);
    this._waterService.setActiveState(this.showActive);
    this._waterService.setArchivedState(this.showArchived);
    if (this.waterBudgets) {
      this.filteredBudgets = this.waterBudgets.filter((a) => a.name.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1);
      this.filteredBudgets = this.filteredBudgets.filter((a) => a.cropName.toLowerCase().indexOf(this.cropFilter.toLowerCase()) > -1);
      if (!this.showActive) {
        this.filteredBudgets = this.filteredBudgets.filter((a) => a.status !== 'Active');
      }
      if (!this.showArchived) {
        this.filteredBudgets = this.filteredBudgets.filter((a) => a.status !== 'Archived');
      }
    }
  }

  public openAddWaterBudgetDialog() {
    this._mdDialog
      .show({
        controller: AddWaterBudgetDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudgets/addwaterbudget-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          waterBudgets: this.waterBudgets,
        },
      })
      .then((returnData) => {
        if (returnData) {
          if (returnData.newBudget) {
            this.createNewBudget(returnData.newBudget);
          }
        }
      });
  }

  private createNewBudget(newBudget: fuse.waterBudgetDto) {
    this._localStorageService.set('waterbudget', newBudget);
    this._state.go('app.water.budgets.detail', { id: '' });
  }

  public gotoBudget(budget: fuse.waterBudgetDto) {
    LocalStorageUtils.updateContextData((context) => {
      context.budgetId = budget.id;
      context.startDayNumber = budget.startDayNumber;
    });

    this._state.go('app.water.budgets.detail', { id: budget.id });
  }

  public openCloneWaterBudgetDialog(waterBudget: fuse.waterBudgetDto) {
    this._mdDialog
      .show({
        controller: CloneWaterBudgetDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudgets/cloneWaterbudget-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          waterBudgets: this.waterBudgets,
          waterBudget: waterBudget,
        },
      })
      .then((returnData) => {
        if (returnData) {
          if (returnData.newBudget) {
            this.createNewBudget(returnData.newBudget);
          }
        }
      });
  }

  public openSweepWaterBudgetsDialog() {
    this._mdDialog
      .show({
        controller: SweepBudgetFromSitesDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudgets/sweepBudgetFromSites-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then((returnData) => {
        if (returnData) {
          this._languageService.showSaveSuccess();
        }
      });
  }

  public toggleButton(button: string): void {
    if (button == 'Active') this.showActive = !this.showActive;
    if (button == 'Archived') this.showArchived = !this.showArchived;
    this.filterBudgets();
  }

  public clearFilter(): void {
    this.nameFilter = '';
    this.cropFilter = '';
  }

  public changeStatus(budget: fuse.waterBudgetDto) {
    const successCallback = () => {
      if (budget.status == 'Archived') {
        this.iArchived++;
        this.iActive--;
      } else {
        this.iActive++;
        this.iArchived--;
      }
      this._languageService.showSaveSuccess();
    }
    const failCallback = () => {
      this._languageService.whoops();
    }
    let text = 'WATER_BUDGETS.ACTIVE_CONFIRM';
    if (budget.status === 'Archived') {
      text = 'WATER_BUDGETS.ARCHIVE_CONFIRM';
    }
    const confirm = this._languageService
      .confirm()
      .title('COMMON.CONFIRM')
      .htmlContent(text)
      .ok('COMMON.SAVE')
      .cancel('COMMON.CANCEL');
    this._languageService.show(confirm.dialog).then(
      () => {
        this._apiService.postGeneric('water/UpdateBudgetStatus', budget, successCallback, failCallback);
        this.filterBudgets();
      },
      () => {
        console.log('cancel');
        if (budget.status === 'Archived') {
          budget.status = 'Active';
        } else {
          budget.status = 'Archived';
        }
      },
    );
  }
}

angular.module('app.water').controller('WaterBudgetsController', WaterBudgetsController);
