import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class SweepBudgetSelectGroupsDialogController extends BaseController {
  public accountGroups = [] as fuse.waterBudgetGroupDto[];

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _languageService: LanguageService;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._languageService = LanguageService;
  }

  $onInit() {
    this.accountGroups = [];
    this.getAccountGroups();
  }

  private getAccountGroups(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId, budgetId: 'null' };
    this._http.get(CommonHelper.getApiUrl('water/GetAccountGroups'), { params: params }).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.accountGroups = response.data as fuse.waterBudgetGroupDto[];
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  public selectGroups() {
    const dialogSites = [] as fuse.waterBudgetSiteDto[];
    this.accountGroups
      .filter((a) => a.isSelected)
      .forEach((group) => {
        group.groupSites.forEach((site) => {
          if (dialogSites.some((a) => a.siteId == site.siteId) == false) {
            dialogSites.push(site);
          }
        });
      });
    this._mdDialog.hide({ dialogSites: dialogSites });
  }

  public closeDialog() {
    if (this.isAnyGroupSelected()) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public isAnyGroupSelected() {
    if (this.accountGroups != null) {
      return this.accountGroups.some((a) => a.isSelected == true);
    } else {
      return false;
    }
  }
}

angular.module('app.water').controller('SweepBudgetSelectGroupsDialogController', SweepBudgetSelectGroupsDialogController);
