import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { unitSizes, AmGraphDataValues } from '@common/enums';

class WaterBudgetProjectionChartComponent implements angular.IComponentOptions {
  bindings = {
    waterBudgetMonths: '<',
    totalArea: '<',
    waterUnitType: '<',
    weatherSource: '<',
    reloadCount: '<',
  };
  controller = WaterBudgetProjectionChartController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/water/waterbudget/components/water-budget-projection-chart.html';
}

interface waterProjectionItem {
  monthYear: string;
  waterBudget: number;
  waterOverride: number;
  cumulativeWaterBudget: number;
  cumulativeWaterOverride: number;
  totalWater: number;
}

class WaterBudgetProjectionChartController {
  private _unitOfMeasureService: UnitOfMeasureService;
  private _languageService: LanguageService;
  private waterProjectionChart: AmCharts.AmSerialChart;
  private waterBudgetMonths: fuse.waterBudgetMonthDto[];
  private volumeLargeUnit: uomUnit;
  private volumeAreaLargeUnit: uomUnit;
  private waterUnitType: number;
  private weatherSource: string;
  private totalArea: number;

  constructor(UnitOfMeasureService: UnitOfMeasureService, LanguageService: LanguageService) {
    this._unitOfMeasureService = UnitOfMeasureService;
    this._languageService = LanguageService;
    this.volumeLargeUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.large);
    this.volumeAreaLargeUnit = this._unitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
  }

  $onChanges(changes) {
    if (changes.waterBudgetMonths?.currentValue) {
      this.displayWaterProjectionChart();
    }

    if (changes.waterUnitType) {
      if (!this.waterUnitType) {
        return;
      }

      this.displayWaterProjectionChart();
    }

    if (changes.reloadCount?.currentValue > 0) {
      this.displayWaterProjectionChart();
    }
  }

  $onDestroy() {
    if (this.waterProjectionChart != null) {
      this.waterProjectionChart.legend = null;
      this.waterProjectionChart.clear();
      this.waterProjectionChart = null;
    }
  }

  private displayWaterProjectionChart() {
    if (
      this.waterBudgetMonths == null ||
      this.waterUnitType == null ||
      this.totalArea == null ||
      this.totalArea == 0 ||
      this.weatherSource == null
    )
      return;
    let dataProvider = [];
    if (this.waterUnitType == 1) {
      dataProvider = this.waterBudgetMonths.map((month) => {
        return {
          monthYear: month.monthYear,
          waterOverride: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.large, month.waterKLOverride),
          waterBudget: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.large, month.waterKLBudget),
          cumulativeWaterOverride: this._unitOfMeasureService.convertFromBase(
            'Volume',
            unitSizes.large,
            month.cumulativeWaterKLOverride,
          ),
          cumulativeWaterBudget: this._unitOfMeasureService.convertFromBase(
            'Volume',
            unitSizes.large,
            month.cumulativeWaterKLBudget,
          ),
          totalWater: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.large, month.totalWaterAllocation),
        } as waterProjectionItem;
      });
    } else {
      dataProvider = this.waterBudgetMonths.map((month) => {
        return {
          monthYear: month.monthYear,
          waterOverride: this._unitOfMeasureService.convertFromBase(
            'Volume/Area',
            unitSizes.large,
            month.waterKLOverride / this.totalArea,
          ),
          waterBudget: this._unitOfMeasureService.convertFromBase(
            'Volume/Area',
            unitSizes.large,
            month.waterKLBudget / this.totalArea,
          ),
          cumulativeWaterOverride: this._unitOfMeasureService.convertFromBase(
            'Volume/Area',
            unitSizes.large,
            month.cumulativeWaterKLOverride / this.totalArea,
          ),
          cumulativeWaterBudget: this._unitOfMeasureService.convertFromBase(
            'Volume/Area',
            unitSizes.large,
            month.cumulativeWaterKLBudget / this.totalArea,
          ),
          totalWater: this._unitOfMeasureService.convertFromBase(
            'Volume/Area',
            unitSizes.large,
            month.totalWaterAllocation / this.totalArea,
          ),
        } as waterProjectionItem;
      });
    }
    const unit = this.waterUnitType == 0 ? this.volumeAreaLargeUnit : this.volumeLargeUnit;
    // #region projection chart
    const mlAxis = {
      id: 'mlAxis',
      dashLength: 1,
      position: 'left',
      title: `${unit}/${this._languageService.instant('COMMON.MONTH')}`,
      minimum: 0,
    } as AmCharts.ValueAxis;
    const cumAxis = {
      id: 'cumAxis',
      dashLength: 1,
      position: 'right',
      title: `${unit} (${this._languageService.instant('COMMON.TOTAL')})`,
      minimum: 0,
    } as AmCharts.ValueAxis;

    const waterOverride = {
      valueField: 'waterOverride',
      title: `${this._languageService.instant('COMMON.BUDGET')} (${unit}/${this._languageService.instant('COMMON.MONTH')})`,
      valueAxis: mlAxis,
      type: 'column',
      clustered: true,
      columnWidth: 0.5,
      alphaField: 'true',
      fillAlphas: 0.8,
      lineColor: '#27AAE0',
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.BUDGET')}: ${val.toFixed(2)}${unit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const cumulativeWaterOverride = {
      valueField: 'cumulativeWaterOverride',
      title: `${this._languageService.instant('COMMON.BUDGETED_CUMULATIVE')} (${unit})`,
      valueAxis: cumAxis,
      type: 'line',
      bullet: 'round',
      lineColor: '#27AAE0',
      lineThickness: 2,
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.BUDGETED_CUMULATIVE')}: ${val.toFixed(2)}${unit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const waterBudget = {
      valueField: 'waterBudget',
      title: `${this._languageService.instant('COMMON.REQUIRED')} (${unit}/${this._languageService.instant('COMMON.MONTH')})`,
      valueAxis: mlAxis,
      type: 'column',
      clustered: true,
      columnWidth: 0.5,
      alphaField: 'true',
      fillAlphas: 0.8,
      lineColor: '#1A237E',
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.REQUIRED')}: ${val.toFixed(2)}${unit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const cumulativeWaterBudget = {
      valueField: 'cumulativeWaterBudget',
      title: `${this._languageService.instant('COMMON.REQUIRED_CUMULATIVE')} (${unit})`,
      valueAxis: cumAxis,
      type: 'line',
      bullet: 'round',
      lineColor: '#1A237E',
      lineThickness: 2,
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.REQUIRED_CUMULATIVE')}: ${val.toFixed(2)}${unit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const totalWater = {
      valueField: 'totalWater',
      title: `${this._languageService.instant('COMMON.TOTAL_BUDGET')} (${unit})`,
      valueAxis: cumAxis,
      type: 'line',
      lineColor: '#CA8000',
      lineThickness: 2,
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.TOTAL_BUDGET')}: ${val.toFixed(2)}${unit}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;

    const projectionChartOption = {
      theme: 'light',
      type: 'serial',
      dataProvider: dataProvider,
      legend: {
        useGraphSettings: true,
        position: 'left',
      } as AmCharts.AmLegend,
      valueAxes: [mlAxis, cumAxis],
      startDuration: 0,
      graphs: [],
      plotAreaFillAlphas: 0.1,
      categoryField: 'monthYear',
      categoryAxis: {
        gridPosition: 'center',
        gridAlpha: 0,
        //parseDates: true,
        axisColor: '#DADADA',
        //dateFormats: [{ period: 'DD', format: 'MMM-YY' }],
        dashLength: 1,
      } as AmCharts.CategoryAxis,
    } as AmCharts.AmSerialChart;
    if (this.weatherSource == 'I') {
      projectionChartOption.graphs.push(waterOverride);
      projectionChartOption.graphs.push(cumulativeWaterOverride);
      projectionChartOption.graphs.push(totalWater);
    } else {
      projectionChartOption.graphs.push(waterOverride);
      projectionChartOption.graphs.push(waterBudget);
      projectionChartOption.graphs.push(cumulativeWaterOverride);
      projectionChartOption.graphs.push(cumulativeWaterBudget);
      projectionChartOption.graphs.push(totalWater);
    }
    this.waterProjectionChart = AmCharts.makeChart(
      'water-budget-projection-chart',
      projectionChartOption,
    ) as AmCharts.AmSerialChart;
    this.waterProjectionChart.validateNow();
  }
}

angular.module('app.water').component('waterBudgetProjectionChart', new WaterBudgetProjectionChartComponent());
