import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { WaterBudgetAdjustHelpDialogController } from './waterBudgetAdjustHelp-dialog.controller';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { WaterConstants } from '../WaterConstants';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class ChangeMonthTotalIrrigationDialogController extends BaseController {
  public budgetMonth: fuse.waterBudgetMonthDto;
  public waterBudgetMonthAdjustActions = angular.copy(WaterConstants.waterBudgetMonthAdjustActions);
  public monthTotalWaterKLOverride: number;
  public monthTotalWaterKLOverridePerHa: number;
  public adjustAction: number;
  public percentOfOverrideValue: number;
  public percentOfBudgetValue: number;
  public volumeAreaLargeUnit: uomUnit;
  public volumeLargeUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  private _dialogSites = [] as fuse.waterBudgetSiteDto[];
  private _totalIrrigationArea: number;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    budgetMonth: fuse.waterBudgetMonthDto,
    dialogSites: fuse.waterBudgetSiteDto[],
    waterUnitType: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.volumeAreaLargeUnit = UnitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
    this.volumeLargeUnit = UnitOfMeasureService.getUnits('Volume', unitSizes.large);

    this.waterBudgetMonthAdjustActions.forEach((item) => {
      if (item.name == 'VolumeAreaUnit') {
        item.description = this.volumeAreaLargeUnit.name;
      } else {
        item.description = this._languageService.instant(item.description);
      }
    });

    this.budgetMonth = budgetMonth;
    this._dialogSites = dialogSites;
    this.adjustAction = waterUnitType;
    this.monthTotalWaterKLOverride = this.budgetMonth.waterKLOverride;
    this.monthTotalWaterKLOverridePerHa = this.budgetMonth.waterKLOverridePerHa;
    this._totalIrrigationArea = this._dialogSites.reduce((sum, site) => sum + site.irrigationArea, 0);
  }

  public submit() {
    if (this.adjustAction == 0) {
      if (this.budgetMonth.waterKLBudgetPerHa != 0) {
        const proportion = this.monthTotalWaterKLOverridePerHa / this.budgetMonth.waterKLBudgetPerHa;
        this._dialogSites.forEach((site) => {
          site.monthList.forEach((siteMonth) => {
            if (siteMonth.dayNumber == this.budgetMonth.dayNumber) {
              siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa * proportion;
              siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
            }
          });
        });
      } else {
        this.budgetMonth.waterKLOverridePerHa = this.monthTotalWaterKLOverridePerHa;
        this._dialogSites.forEach((site) => {
          site.monthList.forEach((siteMonth) => {
            if (siteMonth.dayNumber == this.budgetMonth.dayNumber) {
              siteMonth.water_KL_Override_PerHa = this.budgetMonth.waterKLOverridePerHa;
              siteMonth.water_KL_Override = this.budgetMonth.waterKLOverridePerHa * siteMonth.irrigationArea;
            }
          });
        });
      }
    } else if (this.adjustAction == 1) {
      if (this.budgetMonth.waterKLBudget != 0) {
        const proportion = this.monthTotalWaterKLOverride / this.budgetMonth.waterKLBudget;
        this._dialogSites.forEach((site) => {
          site.monthList.forEach((siteMonth) => {
            if (siteMonth.dayNumber == this.budgetMonth.dayNumber) {
              siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa * proportion;
              siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
            }
          });
        });
      } else {
        this.budgetMonth.waterKLOverride = this.monthTotalWaterKLOverride;
        this.budgetMonth.waterKLOverridePerHa = this.budgetMonth.waterKLOverride / this._totalIrrigationArea;
        this._dialogSites.forEach((site) => {
          site.monthList.forEach((siteMonth) => {
            if (siteMonth.dayNumber == this.budgetMonth.dayNumber) {
              siteMonth.water_KL_Override_PerHa = this.budgetMonth.waterKLOverridePerHa;
              siteMonth.water_KL_Override = this.budgetMonth.waterKLOverridePerHa * siteMonth.irrigationArea;
            }
          });
        });
      }
    } else if (this.adjustAction == 2) {
      this._dialogSites.forEach((site) => {
        site.monthList.forEach((siteMonth) => {
          if (siteMonth.dayNumber == this.budgetMonth.dayNumber) {
            siteMonth.water_KL_Override = siteMonth.water_KL_Budget;
            siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa;
          }
        });
      });
    } else if (this.adjustAction == 3) {
      //percent of desired value
      this._dialogSites.forEach((site) => {
        site.monthList.forEach((siteMonth) => {
          if (siteMonth.dayNumber == this.budgetMonth.dayNumber) {
            siteMonth.water_KL_Override = (siteMonth.water_KL_Budget * this.percentOfBudgetValue) / 100;
            siteMonth.water_KL_Override_PerHa = (siteMonth.water_KL_Budget_PerHa * this.percentOfBudgetValue) / 100;
          }
        });
      });
    } else if (this.adjustAction == 4) {
      //percent of current value
      this._dialogSites.forEach((site) => {
        site.monthList.forEach((siteMonth) => {
          if (siteMonth.dayNumber == this.budgetMonth.dayNumber) {
            siteMonth.water_KL_Override = (siteMonth.water_KL_Override * this.percentOfOverrideValue) / 100;
            siteMonth.water_KL_Override_PerHa = (siteMonth.water_KL_Override_PerHa * this.percentOfOverrideValue) / 100;
          }
        });
      });
    }
    this._mdDialog.hide({ dialogSites: this._dialogSites });
  }

  public closeDialog() {
    if (this.scope['changeMonthTotalIrrigationForm'].$pristine == false) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public openMonthAdjustHelpDialog(actionId: number) {
    this._mdDialog
      .show({
        controller: WaterBudgetAdjustHelpDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/waterBudgetAdjustHelp-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        multiple: true,
        locals: {
          key: 'month',
          actionId: actionId,
        },
      })
      .then(() => {});
  }
}

angular.module('app.water').controller('ChangeMonthTotalIrrigationDialogController', ChangeMonthTotalIrrigationDialogController);
