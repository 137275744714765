import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class SweepBudgetSelectSitesDialogController extends BaseController {
  public dialogSites = [] as fuse.waterBudgetSiteDto[];

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _languageService: LanguageService;

  private accountSites: fuse.waterBudgetSiteDto[];
  private listedSites: fuse.waterBudgetSiteDto[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    listedSites: fuse.waterBudgetSiteDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._languageService = LanguageService;

    this.listedSites = listedSites;
  }

  $onInit() {
    this.dialogSites = [];
    this.getAccountSites().then(() => {
      this.accountSites.forEach((site) => {
        if (this.listedSites.some((a) => a.siteId == site.siteId) == false) {
          this.dialogSites.push(site);
        }
      });
    });
  }

  private getAccountSites(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('water/GetAccountSites')).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.accountSites = response.data as fuse.waterBudgetSiteDto[];
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  public selectSites() {
    this._mdDialog.hide({ dialogSites: this.dialogSites.filter((a) => a.isSelected) });
  }

  public closeDialog() {
    if (this.isSiteSelected()) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this.dialogSites.forEach((site) => {
            site.isInWaterBudget = false;
          });
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public isSiteSelected() {
    if (this.dialogSites != null) {
      return this.dialogSites.some((a) => a.isSelected == true);
    } else {
      return false;
    }
  }
}

angular.module('app.water').controller('SweepBudgetSelectSitesDialogController', SweepBudgetSelectSitesDialogController);
