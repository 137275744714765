import * as angular from 'angular';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { SWANConstants } from '@common/SWANConstants';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { DayNumberService } from '@services/day-number.service';
import { SweepBudgetSelectGroupsDialogController } from './sweepBudgetSelectGroups-dialog.controller';
import { SweepBudgetSelectSitesDialogController } from './sweepBudgetSelectSites-dialog.controller';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class SweepBudgetFromSitesDialogController extends BaseController {
  public minDate = SWANConstants.MinDate;
  public startDate: Date;
  public endDate: Date;
  public isAnySiteSelected = false;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _dayNumebrService: DayNumberService;
  private _languageService: LanguageService;

  private listedSites: fuse.waterBudgetSiteDto[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._dayNumebrService = DayNumberService;
    this._languageService = LanguageService;

    this.scope['datePickerLocale'] =  DateUtils.Locale.AngularJS.DatePicker.monthAndYearShort();
    this.listedSites = [];
  }

  public sweepBudgetFromSites() {
    const confirm = this._languageService
      .confirm()
      .title('COMMON.CONFIRM')
      .htmlContent('WATER.BUDGET.CONFIRM.SWEEP_BUDGET')
      .multiple(true)
      .ok('COMMON.YES')
      .cancel('COMMON.NO');
    this._languageService.show(confirm).then(
      () => {
        this.removeBudgetFromSites().then(
          () => {
            this._mdDialog.hide();
            this._languageService.showSaveSuccess();
          },
          () => {
            this._languageService.whoops();
          },
        );
      },
      () => {
        // user canceled the close
      },
    );
  }

  public closeDialog() {
    if (this.scope['sweepbudgetfromsitesForm'].$dirty) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public openSelectSitesDialog() {
    const listedSites = angular.copy(this.listedSites);
    this._mdDialog
      .show({
        controller: SweepBudgetSelectSitesDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudgets/sweepBudgetSelectSites-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        multiple: true,
        locals: {
          listedSites: listedSites,
        },
      })
      .then((returnData) => {
        if (returnData) {
          const dialogSites = returnData.dialogSites as fuse.waterBudgetSiteDto[];
          dialogSites.forEach((dialogSite) => {
            if (this.listedSites.some((a) => a.siteId == dialogSite.siteId) == false) {
              this.listedSites.push(dialogSite);
            }
          });
          this.refreshListedSitesInfo();
        }
      });
  }

  public openSelectGroupsDialog() {
    this._mdDialog
      .show({
        controller: SweepBudgetSelectGroupsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudgets/sweepBudgetSelectGroups-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        multiple: true,
      })
      .then((returnData) => {
        if (returnData) {
          const dialogSites = returnData.dialogSites as fuse.waterBudgetSiteDto[];
          dialogSites.forEach((dialogSite) => {
            if (this.listedSites.some((a) => a.siteId == dialogSite.siteId) == false) {
              this.listedSites.push(dialogSite);
            }
          });
          this.refreshListedSitesInfo();
        }
      });
  }

  public removeSiteFromList(site: fuse.waterBudgetSiteDto) {
    const index = this.listedSites.findIndex((a) => a.siteId == site.siteId);
    this.listedSites.splice(index, 1);
    this.checkAnySiteSelected();
  }

  private refreshListedSitesInfo() {
    if (this.endDate && this.endDate.getDate() == 1) {
      //set to last date of the month
      this.endDate.addMonths(1).addDays(-1);
    }

    if (this.scope['sweepbudgetfromsitesForm'].$invalid) {
      return;
    }

    if (!this.listedSites.length) {
      return;
    }

    this.getListedSitesInfo().then(() => {
      this.checkAnySiteSelected();
    });
  }

  public changeSelectDate() {
    this.refreshListedSitesInfo();
  }

  private getListedSitesInfo(): angular.IPromise<void> {
    const startDayNumber = this._dayNumebrService.convertLocaleDateToLocaleDayNumber(this.startDate);
    const endDayNumber = this._dayNumebrService.convertLocaleDateToLocaleDayNumber(this.endDate);
    const defer = this._q.defer<void>();
    const body = {
      accountId: this.accountId,
      startDayNumber: startDayNumber,
      endDayNumber: endDayNumber,
      listedSites: this.listedSites,
    } as fuse.listedSitesWaterBudgetInfoDto;
    this._http.post(CommonHelper.getApiUrl('water/GetListedSitesInfo'), JSON.stringify(body)).then(
      (response) => {
        const returnSites = response.data as fuse.waterBudgetSiteDto[];
        returnSites.forEach((returnSite) => {
          const site = this.listedSites.find((a) => a.siteId == returnSite.siteId);
          if (site != null) {
            site.totalBudgets = returnSite.totalBudgets;
            site.isApplied = returnSite.isApplied;
          }
        });
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private removeBudgetFromSites(): angular.IPromise<void> {
    const startDayNumber = this._dayNumebrService.convertLocaleDateToLocaleDayNumber(this.startDate);
    const endDayNumber = this._dayNumebrService.convertLocaleDateToLocaleDayNumber(this.endDate);
    const defer = this._q.defer<void>();
    const body = {
      accountId: this.accountId,
      startDayNumber: startDayNumber,
      endDayNumber: endDayNumber,
      listedSites: this.listedSites.filter((a) => a.isSelected),
    } as fuse.listedSitesWaterBudgetInfoDto;
    this._http.post(CommonHelper.getApiUrl('water/SweepBudgetFromSites'), JSON.stringify(body)).then(
      (response) => {
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  public checkAnySiteSelected() {
    this.isAnySiteSelected = this.listedSites.some((a) => a.isSelected && a.totalBudgets != 0);
  }
}

angular.module('app.water').controller('SweepBudgetFromSitesDialogController', SweepBudgetFromSitesDialogController);
