import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';

class WaterBudgetSummaryTableComponent implements angular.IComponentOptions {
  bindings = {
    budgetDataSource: '<',
    weatherSource: '<',
  };
  controller = WaterBudgetSummaryTableController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/water/waterbudget/components/water-budget-summary-table.html';
}

class WaterBudgetSummaryTableController {
  private _unitOfMeasureService: UnitOfMeasureService;
  private _languageService: LanguageService;
  public waterBudget: fuse.dashboardWeatherItem[];
  public volumeLargeUnit: uomUnit;
  public volumeAreaLargeUnit: uomUnit;
  public fluidDepthUnit: uomUnit;

  constructor(UnitOfMeasureService: UnitOfMeasureService, LanguageService: LanguageService) {
    this._unitOfMeasureService = UnitOfMeasureService;
    this._languageService = LanguageService;
    this.volumeLargeUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.large);
    this.volumeAreaLargeUnit = this._unitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
    this.fluidDepthUnit = this._unitOfMeasureService.getUnits('Fluid Depth', unitSizes.normal);
  }

  public textConditionColor(desiredValue: number, budgetedValue: number): string {
    if (desiredValue == null || isNaN(desiredValue) || desiredValue == 0) return null;
    else if (budgetedValue / desiredValue <= 0.9) return 'red';
    else if (budgetedValue / desiredValue < 0.99) return 'orange';
    else return 'rgba(0, 0, 0, 0.7)';
  }

  public alertConditionDisplay(desiredValue: number, budgetedValue: number): boolean {
    if (desiredValue == null || isNaN(desiredValue) || desiredValue == 0 || budgetedValue == null || isNaN(budgetedValue))
      return false;
    else if (budgetedValue / desiredValue <= 0.9) return true;
    else if (budgetedValue / desiredValue < 0.99) return true;
    else if (budgetedValue / desiredValue < 1.05) return false;
    else return true;
  }

  public plantWaterTextConditionColor(reductionPercent: number): string {
    if (reductionPercent >= 10) return 'red';
    else if (reductionPercent > 1) return 'orange';
    else return null;
  }

  public plantWaterAlertConditionDisplay(reductionPercent: number): boolean {
    if (reductionPercent > 1) return true;
    else return false;
  }

  public plantWaterAlertTooltip(reductionPercent: number): string {
    if (reductionPercent >= 10) return this._languageService.instant('WATER.BUDGET.WARNING.LT_90_PCT_WATER');
    else if (reductionPercent > 1) return this._languageService.instant('WATER.BUDGET.WARNING.GT_90_PCT_WATER');
    else return null;
  }

  public summaryAlertTooltip(desiredValue: number, budgetedValue: number): string {
    if (desiredValue == null || isNaN(desiredValue) || desiredValue == 0 || budgetedValue == null || isNaN(budgetedValue)) {
      return '';
    } else if (budgetedValue / desiredValue <= 0.9) {
      return this._languageService.instant('WATER.BUDGET.WARNING.BUDGET_LT_90_PCT');
    } else if (budgetedValue / desiredValue <= 0.99) {
      return this._languageService.instant('WATER.BUDGET.WARNING.BUDGET_LT_REQUIRED');
    } else if (budgetedValue / desiredValue > 1.05) {
      return this._languageService.instant('WATER.BUDGET.WARNING.BUDGET_GT_REQUIRED');
    } else {
      return '';
    }
  }
}

angular.module('app.water').component('waterBudgetSummaryTable', new WaterBudgetSummaryTableComponent());
