import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import {  PermissionService } from '@services/permission.service';
import { WaterBudgetAdjustHelpDialogController } from './waterBudgetAdjustHelp-dialog.controller';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { WaterConstants } from '../WaterConstants';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class ChangeSiteTotalIrrigationDialogController extends BaseController {
  public waterBudgetSiteAdjustActions = angular.copy(WaterConstants.waterBudgetSiteAdjustActions);
  public siteTotalWaterKLOverride: number;
  public siteTotalWaterKLOverridePerHa: number;
  public adjustAction: number;
  public percentOfOverrideValue: number;
  public percentOfBudgetValue: number;
  public volumeAreaLargeUnit: uomUnit;
  public volumeLargeUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  private dialogSite = {} as fuse.waterBudgetSiteDto;
  private totalMonths: number;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    dialogSite: fuse.waterBudgetSiteDto,
    waterUnitType: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.volumeAreaLargeUnit = UnitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
    this.volumeLargeUnit = UnitOfMeasureService.getUnits('Volume', unitSizes.large);

    this.waterBudgetSiteAdjustActions.forEach((item) => {
      if (item.name == 'VolumeAreaUnit') {
        item.description = this.volumeAreaLargeUnit.name;
      } else {
        item.description = this._languageService.instant(item.description);
      }
    });

    this.dialogSite = dialogSite;
    this.adjustAction = waterUnitType;
    this.siteTotalWaterKLOverride = this.dialogSite.totalWaterKLOverride;
    this.siteTotalWaterKLOverridePerHa = this.dialogSite.totalWaterKLOverridePerHa;
    this.totalMonths = this.dialogSite.monthList.length;
  }

  public submit() {
    if (this.adjustAction == 0) {
      if (this.dialogSite.totalWaterKLBudgetPerHa != 0) {
        const proportion = this.siteTotalWaterKLOverridePerHa / this.dialogSite.totalWaterKLBudgetPerHa;
        this.dialogSite.monthList.forEach((siteMonth) => {
          siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa * proportion;
          siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
        });
      } else {
        const waterPerHaPerMonth = this.siteTotalWaterKLOverridePerHa / this.totalMonths;
        this.dialogSite.monthList.forEach((siteMonth) => {
          siteMonth.water_KL_Override_PerHa = waterPerHaPerMonth;
          siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
        });
      }
    } else if (this.adjustAction == 1) {
      this.dialogSite.totalWaterKLOverride = this.siteTotalWaterKLOverride;
      this.dialogSite.totalWaterKLOverridePerHa = this.dialogSite.totalWaterKLOverride / this.dialogSite.irrigationArea;
      this.dialogSite.monthList.forEach((siteMonth) => {
        siteMonth.water_KL_Override_PerHa = this.dialogSite.totalWaterKLOverridePerHa / this.totalMonths;
        siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
      });
    } else if (this.adjustAction == 2) {
      this.dialogSite.monthList.forEach((siteMonth) => {
        siteMonth.water_KL_Override = siteMonth.water_KL_Budget;
        siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa;
      });
    } else if (this.adjustAction == 3) {
      //percent of desired value
      this.dialogSite.monthList.forEach((siteMonth) => {
        siteMonth.water_KL_Override = (siteMonth.water_KL_Budget * this.percentOfBudgetValue) / 100;
        siteMonth.water_KL_Override_PerHa = (siteMonth.water_KL_Budget_PerHa * this.percentOfBudgetValue) / 100;
      });
    } else if (this.adjustAction == 4) {
      //percent of current value
      this.dialogSite.monthList.forEach((siteMonth) => {
        siteMonth.water_KL_Override = (siteMonth.water_KL_Override * this.percentOfOverrideValue) / 100;
        siteMonth.water_KL_Override_PerHa = (siteMonth.water_KL_Override_PerHa * this.percentOfOverrideValue) / 100;
      });
    } else if (this.adjustAction == 5) {
      if (this.dialogSite.totalWaterKLBudget != 0) {
        const proportion = this.siteTotalWaterKLOverride / this.dialogSite.totalWaterKLBudget;
        this.dialogSite.monthList.forEach((siteMonth) => {
          siteMonth.water_KL_Override = siteMonth.water_KL_Budget * proportion;
          siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa * proportion;
        });
      } else {
        const waterPerHaPerMonth = this.siteTotalWaterKLOverride / this.dialogSite.irrigationArea / this.totalMonths;
        this.dialogSite.monthList.forEach((siteMonth) => {
          siteMonth.water_KL_Override_PerHa = waterPerHaPerMonth;
          siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
        });
      }
    }
    this._mdDialog.hide({ dialogSite: this.dialogSite });
  }

  public closeDialog() {
    if (this.scope['changeSiteTotalIrrigationForm'].$pristine == false) {
      const confirm = this._languageService.closeDialog();
      this._mdDialog.show(confirm).then(
        () => {
          this._mdDialog.hide();
        },
        () => {
          // user canceled the close - don't close the form
        },
      );
    } else {
      this._mdDialog.hide();
    }
  }

  public openSiteAdjustHelpDialog(actionId: number) {
    this._mdDialog
      .show({
        controller: WaterBudgetAdjustHelpDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/waterBudgetAdjustHelp-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        multiple: true,
        locals: {
          key: 'site',
          actionId: actionId,
        },
      })
      .then(() => {});
  }
}

angular.module('app.water').controller('ChangeSiteTotalIrrigationDialogController', ChangeSiteTotalIrrigationDialogController);
