import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class WaterBudgetAdjustHelpDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _sce: angular.ISCEService;
  private _languageService: LanguageService;

  private key: string;
  private actionId: number;
  public title: string;
  public content: string;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $sce: angular.ISCEService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    key: string,
    actionId: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._sce = $sce;
    this._languageService = LanguageService;

    this.key = key;
    this.actionId = actionId;
  }

  $onInit() {
    if (this.key == 'all') {
      this.title = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_ALL_TITLE');
      if (this.actionId == 0) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_ALL1');
      } else if (this.actionId == 1) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_ALL2');
      } else if (this.actionId == 2) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_ALL2');
      } else if (this.actionId == 3) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_ALL4');
      } else if (this.actionId == 4) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_ALL5');
      }
    } else if (this.key == 'month') {
      this.title = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_MTH_TITLE');
      if (this.actionId == 0) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_MTH1');
      } else if (this.actionId == 1) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_MTH2');
      } else if (this.actionId == 2) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_MTH3');
      } else if (this.actionId == 3) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_MTH4');
      } else if (this.actionId == 4) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_MTH5');
      }
    } else if (this.key == 'site') {
      this.title = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_SITE_TITLE');
      if (this.actionId == 0) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_SITE1');
      } else if (this.actionId == 1) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_SITE2');
      } else if (this.actionId == 5) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_SITE3');
      } else if (this.actionId == 2) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_SITE4');
      } else if (this.actionId == 3) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_SITE5');
      } else if (this.actionId == 4) {
        this.content = this._languageService.instant('WATER.BUDGET.HELP.CHANGE_SITE6');
      }
    }
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public trustedHtml(text: string): string {
    return this._sce.trustAsHtml(text);
  }
}

angular.module('app.water').controller('WaterBudgetAdjustHelpDialogController', WaterBudgetAdjustHelpDialogController);
