import * as angular from 'angular';
import * as moment from 'moment';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { WaterService } from '@services/water.service';
import { AccountsService } from '@services/administration/accounts.service';
import { DayNumberService } from '@services/day-number.service';
import { FetchDataService } from '@services/fetch-data.service';
import { NotifyEvents, NotifyingService} from '@services/notifying.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { AddGroupToWaterBudgetDialogController } from './addGroupToWaterBudget-dialog.controller';
import { AddSiteToWaterBudgetDialogController } from './addSiteToWaterBudget-dialog.controller';
import { ApplyBudgetToSitesDialogController } from './applyBudgetToSites-dialog.controller';
import { ChangeBudgetTotalIrrigationDialogController } from './changeBudgetTotalIrrigation-dialog.controller';
import { ChangeMonthTotalIrrigationDialogController } from './changeMonthTotalIrrigation-dialog.controller';
import { ChangeSiteTotalIrrigationDialogController } from './changeSiteTotalIrrigation-dialog.controller';
import { ChangeWeatherStationDialogController } from './changeWeatherStation-dialog.controller';
import { WaterBudgetUpdateMultiSitesDialogController } from './updateMultiSites-dialog.controller';
import { WaterBudgetHelpDialogController } from './waterBudgetHelp-dialog.controller';
import { IWaterBudgetSiteSetting, WaterConstants } from '../WaterConstants';
import { WaterBudget } from 'src/app/_DBContext/WaterBudget';
import { WaterBudgetMonth } from 'src/app/_DBContext/WaterBudgetMonth';
import { WaterBudgetSite } from 'src/app/_DBContext/WaterBudgetSite';
import { BaseController, PostSaveActions } from 'src/app/base.controller';

enum WaterBudgetSummaryTabEnums {
  Setting,
  Projection,
  Summary,
}

enum WaterBudgetPrimaryTabEnums {
  Sites,
  Weather,
  Adjustments,
}

export class WaterBudgetController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _timeout: angular.ITimeoutService;
  private _accountService: AccountsService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _fetchDataService: FetchDataService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _waterService: WaterService;

  public waterBudgetStatuses = WaterConstants.waterBudgetStatuses;
  public requiredColumnVisibilities = WaterConstants.requiredColumnVisibilities;

  private accountDetail: fuse.accountDetail;
  private waterBudgets = [] as fuse.waterBudgetDto[];
  public accountCrops = [] as fuse.waterBudgetCropDto[];
  public accountSites = [] as fuse.waterBudgetSiteDto[];
  private waterBudgetSites = [] as WaterBudgetSite[];
  private waterBudgetMonths = [] as WaterBudgetMonth[];
  private waterBudgetSiteMonthsFromDb = [] as fuse.waterBudgetSiteMonthDto[];
  public accountGroups = [] as fuse.waterBudgetGroupDto[];
  public searchKeyword = ''; //used in html
  public budget: fuse.waterBudgetDto;
  public isNewBudget = false;
  public isBudgetChanged = false;
  public isBudgetNameExist = false;
  public isBudgetApplied = false;
  private isSiteMonthValueChanged = false;
  public maximumDate: Date;
  public minimumDate: Date;
  public startDate: Date;
  public endDate: Date;
  public budgetDataSource = {} as fuse.waterBudgetDataSourceDto;
  private weatherStationStatics = [] as fuse.waterBudgetWeatherMonthDto[];
  private isSelectAllBudgetSites = false;
  public summaryTab: number;
  public primaryTab: number;
  public waterUnitType: number;
  public isCropRequiredHidden = false;
  public reloadCount = 0;
  public volumeLargeUnit: uomUnit;
  public volumeAreaLargeUnit: uomUnit;
  public areaUnit: uomUnit;
  public fluidDepthUnit: uomUnit;
  private originalStatus: string;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    $timeout: angular.ITimeoutService,
    AccountsService: AccountsService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    FetchDataService: FetchDataService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    WaterService: WaterService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._timeout = $timeout;
    this._accountService = AccountsService;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._fetchDataService = FetchDataService;
    this._waterService = WaterService;

    this.entityManager = DataEntityService.manager;
    this.volumeLargeUnit = UnitOfMeasureService.getUnits('Volume', unitSizes.large);
    this.volumeAreaLargeUnit = UnitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
    this.areaUnit = UnitOfMeasureService.getUnits('Area', unitSizes.normal);
    this.fluidDepthUnit = UnitOfMeasureService.getUnits('Fluid Depth', unitSizes.normal);
    this.scope['datePickerLocale'] = DateUtils.Locale.AngularJS.DatePicker.monthAndYearShort();
    this.scope['sitesForm'] = {};
    this.scope['summaryForm'] = {};
    this.scope['weatherForm'] = {};
    this.scope['adjustmentsForm'] = {};
    this.waterUnitType = 0;

    NotifyingService.subscribe(NotifyEvents.App.SaveChanges.WaterBudget, $scope, (_event: angular.IAngularEvent, data: PostSaveActions)=> {
      this.saveChanges(data);
    });
  }

  private getAccountDetail(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._accountService
      .getSystemAccountById(this.accountId)
      .then((result) => {
        this.accountDetail = result;
        defer.resolve();
      })
      .catch((response) => {
        defer.reject(response);
      });
    return defer.promise;
  }

  private getAccountWaterBudgets(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId };
    this._http.get(CommonHelper.getApiUrl('water/GetWaterBudgets'), { params: params }).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.waterBudgets = response.data as fuse.waterBudgetDto[];
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private getAccountCrops(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId };
    this._http.get(CommonHelper.getApiUrl('water/GetAccountCrops'), { params: params }).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.accountCrops = response.data as fuse.waterBudgetCropDto[];
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private getAccountSites(startDayNumber: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { startDayNumber: startDayNumber };
    this._http.get(CommonHelper.getApiUrl('water/GetAccountSites'), { params: params }).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.accountSites = response.data as fuse.waterBudgetSiteDto[];
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private getAccountGroups(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId };
    this._http.get(CommonHelper.getApiUrl('water/GetAccountGroups'), { params: params }).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.accountGroups = response.data as fuse.waterBudgetGroupDto[];
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private getBudgetWeatherStatistics(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    const params = {
      accountId: this.accountDetail.authAccountId,
      etoStationId: this.budget.etoStationId,
      rainStationId: this.budget.rainStationId,
      budgetStartMonth: this.budget.startDate.getMonth() + 1,
      budgetEndMonth: this.budget.endDate.getMonth() + 1,
    };

    this._http.get(CommonHelper.getApiUrl('water/getBudgetWeatherStatistics'), { params: params }).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.weatherStationStatics = response.data as fuse.waterBudgetWeatherMonthDto[];
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );

    return defer.promise;
  }

  private getWaterBudgetSiteMonths(budgetId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { budgetId: budgetId };
    this._http.get(CommonHelper.getApiUrl('water/GetWaterBudgetSiteMonths'), { params: params }).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.waterBudgetSiteMonthsFromDb = response.data as fuse.waterBudgetSiteMonthDto[];
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private fetchWaterBudget(budgetId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._fetchDataService
      .fetchWaterBudget(budgetId)
      .then((data) => {
        const waterBudget = data[0] as WaterBudget;
        this.budget = {
          id: waterBudget.Id,
          name: waterBudget.Name,
          status: waterBudget.Status,
          cropId: waterBudget.CropId,
          startDayNumber: waterBudget.StartDayNumber,
          endDayNumber: waterBudget.EndDayNumber,
          etoStationId: waterBudget.EtoWeatherStationId,
          rainStationId: waterBudget.RainWeatherStationId,
          defaultKLPerHa: waterBudget.DefaultKLPerHa,
          systemEfficiency: waterBudget.DefaultSystemEfficiencyPct,
          usefulRainfall: waterBudget.DefaultUsefulRainfallPct,
          appliedDate: waterBudget.AppliedToSitesWhen,
          weatherSource: waterBudget.WeatherSource,
        } as fuse.waterBudgetDto;
        this.originalStatus = waterBudget.Status;
        defer.resolve();
      })
      .catch((reason) => {
        console.log(reason);
        defer.reject(reason);
      });
    return defer.promise;
  }

  private fetchWaterBudgetSites(budgetId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._fetchDataService.fetchWaterBudgetSites(budgetId).then(
      (data) => {
        this.waterBudgetSites = data as WaterBudgetSite[];
        this.accountSites.forEach((site) => {
          if (this.waterBudgetSites.some((a) => a.AssetId == site.siteId)) {
            site.isInWaterBudget = true;
          } else {
            site.isInWaterBudget = false;
          }
        });
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private fetchWaterBudgetMonths(budgetId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._fetchDataService.fetchWaterBudgetMonths(budgetId).then(
      (data) => {
        this.waterBudgetMonths = data as WaterBudgetMonth[];
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  $onInit() {
    this._dataEntityService.clear();
    const promises = [] as angular.IPromise<void>[];

    promises.push(this.getAccountDetail());
    promises.push(this.getAccountCrops());
    promises.push(this.getAccountWaterBudgets());
    promises.push(this.getAccountGroups());

    const context = LocalStorageUtils.contextData;

    if (context.budgetId != null) {
      this.isNewBudget = false;
      this.summaryTab = WaterBudgetSummaryTabEnums.Projection;
      this.budget = { id: context.budgetId } as fuse.waterBudgetDto;
      promises.push(this.getAccountSites(context.startDayNumber));
    } else {
      //new or cloned budget
      this.isNewBudget = true;
      this.summaryTab = WaterBudgetSummaryTabEnums.Setting;
      this.budget = this._localStorageService.get('waterbudget') as fuse.waterBudgetDto;
      this.budget.startDate = moment(this.budget.startDate).toDate();
      this.budget.endDate = moment(this.budget.endDate).toDate();
      this.budget.startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.budget.startDate);
      this.budget.endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.budget.endDate);
      this.originalStatus = this.budget.status;
      promises.push(this.getAccountSites(this.budget.startDayNumber));
    }
    this._q.all(promises).then(() => {
      this.initialiseFirst();
    });
  }

  private initialiseFirst() {
    const context = LocalStorageUtils.contextData;
    const promises = [] as angular.IPromise<void>[];

    if (this.isNewBudget == false) {
      //it is an exist budget
      const budgetId = context.budgetId;

      promises.push(this.fetchWaterBudget(budgetId));
      promises.push(this.fetchWaterBudgetSites(budgetId));
      promises.push(this.fetchWaterBudgetMonths(budgetId));
      promises.push(this.getWaterBudgetSiteMonths(budgetId));
    } else {
      //for clones only
      if (this.budget && this.budget.id && this.budget.id !== -1) {
        promises.push(this.getWaterBudgetSiteMonths(this.budget.id));
      }

      //it is a new/clone budget
      const waterBudgetType: any = this.entityManager.metadataStore.getEntityType('WaterBudget');
      const waterBudgetEntity = waterBudgetType.createEntity() as WaterBudget;

      waterBudgetEntity.Name = this.budget.name;
      waterBudgetEntity.AuthAccountId = this.accountId;
      waterBudgetEntity.StartDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.budget.startDate);
      waterBudgetEntity.EndDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.budget.endDate);
      waterBudgetEntity.Status = this.budget.status;
      waterBudgetEntity.DefaultKLPerHa = this.budget.defaultKLPerHa;
      waterBudgetEntity.DefaultSystemEfficiencyPct = this.budget.systemEfficiency;
      waterBudgetEntity.DefaultUsefulRainfallPct = this.budget.usefulRainfall;
      waterBudgetEntity.CropId = this.budget.cropId;
      waterBudgetEntity.EtoWeatherStationId = this.budget.etoStationId;
      waterBudgetEntity.RainWeatherStationId = this.budget.rainStationId;
      waterBudgetEntity.WeatherSource = this.budget.weatherSource;

      this.entityManager.addEntity(waterBudgetEntity);
      this.budget.id = waterBudgetEntity.Id; // overwrites id if this is a clone

      if (this.budget.waterBudgetSites != null) {
        this.accountSites.forEach((site) => {
          if (this.budget.waterBudgetSites.some((a) => a.siteId == site.siteId)) {
            const budgetSite = this.budget.waterBudgetSites.find((a) => a.siteId == site.siteId);
            site.isInWaterBudget = true;
            site.waterBudgetId = this.budget.id;
            //create waterBudgetSite entity
            site.systemEfficiency = budgetSite.systemEfficiency;
            site.rainfallAdjustment = budgetSite.rainfallAdjustment;
            const waterBudgetSiteType: any = this.entityManager.metadataStore.getEntityType('WaterBudgetSite');
            const waterBudgetSiteEntity = waterBudgetSiteType.createEntity() as WaterBudgetSite;
            waterBudgetSiteEntity.AssetId = site.siteId;
            waterBudgetSiteEntity.BudgetId = site.waterBudgetId;
            waterBudgetSiteEntity.SystemEfficiencyPct = site.systemEfficiency;
            waterBudgetSiteEntity.UsefulRainfallPct = site.rainfallAdjustment;
            this.entityManager.addEntity(waterBudgetSiteEntity);
            this.waterBudgetSites.push(waterBudgetSiteEntity);
          }
        });
      }
      this._localStorageService.remove('waterBudget');
    }
    this._q.all(promises).then(() => {
      this.isBudgetApplied = this.budget.appliedDate != null;
      this.initialise();
    });
  }

  private initialise() {
    if (this.waterBudgets) {
      const waterBudgetDto = this.waterBudgets.find((a) => a.id == this.budget.id);
      if (waterBudgetDto) {
        this.budget.startDate = this._dayNumberService.convertDayNumberToLocaleDate(this.budget.startDayNumber);
        this.budget.endDate = this._dayNumberService.convertDayNumberToLocaleDate(this.budget.endDayNumber);
        this.budget.cropName = waterBudgetDto.cropName;
        if (this.budget.weatherSource == 'W') {
          this.budget.etoStationName = waterBudgetDto.etoStationName;
          this.budget.rainStationName = waterBudgetDto.rainStationName;
        } else if (this.budget.weatherSource == 'I') {
          this.budget.etoStationName = WaterConstants.weatherSource_ignore;
          this.budget.rainStationName = WaterConstants.weatherSource_ignore;
        } else if (this.budget.weatherSource == 'M') {
          this.budget.etoStationName = WaterConstants.weatherSource_manual;
          this.budget.rainStationName = WaterConstants.weatherSource_manual;
        }
      }
    }

    if (this.isNewBudget) {
      //new or cloned budget. need create waterbudgetmonth entities
      this.isBudgetChanged = true;

      if (!this.waterBudgetMonths.length && this.budget.waterBudgetMonths) {
        //cloned budget
        this.isSiteMonthValueChanged = true;
        this.budget.waterBudgetMonths.forEach((budgetMonth) => {
          const waterBudgetMonthType: any = this.entityManager.metadataStore.getEntityType('WaterBudgetMonth');
          const waterBudgetMonthEntity = waterBudgetMonthType.createEntity() as WaterBudgetMonth;
          waterBudgetMonthEntity.BudgetId = this.budget.id;
          waterBudgetMonthEntity.dayNumber = budgetMonth.dayNumber;
          waterBudgetMonthEntity.MonthStartDate = this._dayNumberService.convertDayNumberToLocaleDate(budgetMonth.dayNumber);
          waterBudgetMonthEntity.TargetCropCoefficient = budgetMonth.desiredCropCoefficient;
          waterBudgetMonthEntity.ThisYearEToPct = budgetMonth.expectedEtoDailyPercent;
          waterBudgetMonthEntity.ThisYearRainPct = budgetMonth.expectedRainMonthlyPercent;
          this.entityManager.addEntity(waterBudgetMonthEntity);
          this.waterBudgetMonths.push(waterBudgetMonthEntity);
        });
      }
    }

    this._timeout(() => {
      this.setControlsDirty();
    }, 100);

    this.startDate = this.budget.startDate;
    this.endDate = this.budget.endDate;
    this.maximumDate = this.budget.startDate.clone().addYears(1).addDays(-1);
    this.minimumDate = this.budget.endDate.clone().addYears(-1).addDays(1);

    this.accountSites.forEach((site) => {
      site.isSelected = false;
      const waterBudgetSite = this.waterBudgetSites.find((a) => a.AssetId == site.siteId);
      if (waterBudgetSite != null) {
        site.waterBudgetId = this.budget.id;
        site.isInWaterBudget = true;
        site.systemEfficiency = waterBudgetSite.SystemEfficiencyPct;
        site.rainfallAdjustment = waterBudgetSite.UsefulRainfallPct;
      } else {
        site.isInWaterBudget = false;
      }
    });
    
    const promises = [] as angular.IPromise<void>[];

    if (this.budget.etoStationId || this.budget.rainStationId) {
      promises.push(this.getBudgetWeatherStatistics());
    }

    this._q.all(promises).then(() => {
      this.initBudgetDataSourceMonthList();
      this.initWaterBudgetSiteMonthList();
      this.updateBudgetWeatherSourceBeau();
      this.updateBudgetCropSource();
      this.calculateWaterBudget();
    });

    if (this.budget.cropId != null) {
      //crop may be changes. need to show alert if the crop is removed
      if (this.accountCrops.some((a) => a.id == this.budget.cropId) == false) {
        const alert = this._languageService.warningDialog(
          `<div style="width:500px; max-width: 500px">` + this._languageService.instant('WATER.BUDGET.CROP_NO_GOOD') + '</div>',
        );

        this._mdDialog.show(alert).then(() => {
          this._mdDialog.hide();
        });
      }
    }
  }

  private initBudgetDataSourceMonthList() {
    const deletedBudgetMonths = this.waterBudgetMonths.filter(
      (a) => a.dayNumber < this.budget.startDayNumber || a.dayNumber >= this.budget.endDayNumber,
    );

    deletedBudgetMonths.forEach((month) => {
      const waterBudgetMonthQuery = breeze.EntityQuery.from('WaterBudgetMonth').toType('WaterBudgetMonth');
      const waterBudgetMonthEntities = this.entityManager.executeQueryLocally(
        waterBudgetMonthQuery,
      ) as WaterBudgetMonth[]; // query the cache (synchronous)
      const waterBudgetMonthEntity = waterBudgetMonthEntities.find((a) => a.Id == month.Id);

      //if the current budget id is the same of in waterbudget site, need set delete in entity
      waterBudgetMonthEntity.entityAspect.setDeleted();
    });

    this.waterBudgetMonths = this.waterBudgetMonths.filter(
      (a) => a.dayNumber >= this.budget.startDayNumber && a.dayNumber < this.budget.endDayNumber,
    );

    this.budgetDataSource.monthList = [];

    const currentDate = this.budget.startDate.clone();

    while (currentDate < this.budget.endDate) {
      const currentDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(currentDate);
      const monthItem = {} as fuse.waterBudgetMonthDto;

      monthItem.dateTime = currentDate.clone();
      monthItem.dayNumber = currentDayNumber;
      monthItem.month = DateUtils.Locale.asDateMonthShort(currentDate);
      monthItem.monthValue = currentDate.getMonth();
      monthItem.monthYear = DateUtils.Locale.asDateMonthAndYearShort(currentDate);
      monthItem.daysInMonth = new Date(currentDate.getFullYear(), monthItem.monthValue + 1, 0).getDate();

      const waterBudgetMonth = this.waterBudgetMonths.find((a) => a.dayNumber == currentDayNumber);

      if (waterBudgetMonth == null) {
        monthItem.expectedEtoDailyPercent = 100;
        monthItem.expectedRainMonthlyPercent = 100;
        monthItem.monthCapWater = null;
        monthItem.monthCapWaterPerHa = null;

        const waterBudgetMonthType: any = this.entityManager.metadataStore.getEntityType('WaterBudgetMonth');
        const waterBudgetMonthEntity = waterBudgetMonthType.createEntity() as WaterBudgetMonth;

        waterBudgetMonthEntity.BudgetId = this.budget.id;
        waterBudgetMonthEntity.dayNumber = currentDayNumber;
        waterBudgetMonthEntity.MonthStartDate = currentDate.clone();
        waterBudgetMonthEntity.ThisYearEToPct = monthItem.expectedEtoDailyPercent;
        waterBudgetMonthEntity.ThisYearRainPct = monthItem.expectedRainMonthlyPercent;

        this.entityManager.addEntity(waterBudgetMonthEntity);
        this.waterBudgetMonths.push(waterBudgetMonthEntity);
      } else {
        monthItem.expectedEtoDailyPercent = waterBudgetMonth.ThisYearEToPct;
        monthItem.expectedRainMonthlyPercent = waterBudgetMonth.ThisYearRainPct;
        monthItem.monthCapWater = waterBudgetMonth.VolumeCapKL;
        monthItem.manualEto = waterBudgetMonth.ManualEto;
        monthItem.manualRain = waterBudgetMonth.ManualRain;
      }

      this.budgetDataSource.monthList.push(monthItem);

      currentDate.addMonths(1);
    }
  }

  private initWaterBudgetSiteMonthList() {
    this.accountSites.forEach((site) => {
      if (site.isInWaterBudget) {
        if (!site.monthList.length) {
          this.budgetDataSource.monthList.forEach((sourceMonth) => {
            const siteMonth = {} as fuse.waterBudgetSiteMonthDto;
            siteMonth.irrigationArea = site.irrigationArea;
            siteMonth.siteId = site.siteId;
            siteMonth.dateTime = sourceMonth.dateTime;
            siteMonth.dayNumber = sourceMonth.dayNumber;
            siteMonth.budgetId = this.budget.id;
            // existing or cloned budgets - there should be no duplicate months for each site.
            const waterBudgetSiteMonthFromDb = this.waterBudgetSiteMonthsFromDb.find(
              (a) =>
                a.siteId == siteMonth.siteId &&
                this._dayNumberService.convertDayNumberToDate(a.dayNumber).getMonth() == siteMonth.dateTime.getMonth(),
            );
            if (waterBudgetSiteMonthFromDb != null) {
              siteMonth.water_KL_Override = waterBudgetSiteMonthFromDb.water_KL_Override;
              siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Override / site.irrigationArea;
              siteMonth.water_KL_Budget = waterBudgetSiteMonthFromDb.water_KL_Budget;
              siteMonth.water_KL_Budget_PerHa = siteMonth.water_KL_Budget / site.irrigationArea;
            }
            site.monthList.push(siteMonth);
          });
        } else {
          this.budgetDataSource.monthList.forEach((sourceMonth) => {
            const siteMonth = site.monthList.find((a) => a.dayNumber == sourceMonth.dayNumber);
            if (siteMonth != null) {
              const waterBudgetSiteMonthFromDb = this.waterBudgetSiteMonthsFromDb.find(
                (a) => a.siteId == siteMonth.siteId && a.dayNumber == siteMonth.dayNumber,
              );
              if (waterBudgetSiteMonthFromDb != null) {
                siteMonth.water_KL_Override = waterBudgetSiteMonthFromDb.water_KL_Override;
                siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Override / site.irrigationArea;
                siteMonth.water_KL_Budget = waterBudgetSiteMonthFromDb.water_KL_Budget;
                siteMonth.water_KL_Budget_PerHa = siteMonth.water_KL_Budget / site.irrigationArea;
              } else {
                //mostly it is a old budget
              }
            } else {
              console.log('test3');
            }
          });
        }
      }
    });
  }

  public gotoWaterBudgets() {
    this._waterService.setKeepFilter(true);
    this._state.go('app.water.budgets');
  }

  public gotoWaterBudget(budget: fuse.waterBudgetDto) {
    LocalStorageUtils.updateContextData((context) => {
      context.budgetId = budget.id;
    });

    this._state.go('app.water.budgets.detail', { id: budget.id });
  }

  public openAddSiteToWaterBudgetDialog() {
    const dialogSites = angular.copy(this.accountSites.filter((a) => a.isInWaterBudget != true));
    this._mdDialog
      .show({
        controller: AddSiteToWaterBudgetDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/addSiteToWaterBudget-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          dialogSites: dialogSites,
        },
      })
      .then((res) => {
        if (res) {
          const dialogSites = res.dialogSites as fuse.waterBudgetSiteDto[];
          this.addMultipleSites(dialogSites);
        }
      });
  }

  private addMultipleSites(dialogSites: fuse.waterBudgetSiteDto[]) {
    const waterBudgetSiteQuery = breeze.EntityQuery.from('WaterBudgetSite').toType('WaterBudgetSite');
    const waterBudgetSiteEntities = this.entityManager.executeQueryLocally(
      waterBudgetSiteQuery,
    ) as WaterBudgetSite[]; // query the cache (synchronous)
    const deletedWaterBudgetSiteEntities = this.entityManager.getEntities(
      'WaterBudgetSite',
      breeze.EntityState.Deleted,
    ) as WaterBudgetSite[];
    dialogSites.forEach((dialogSite) => {
      if (dialogSite.isInWaterBudget) {
        this.isBudgetChanged = true;
        this.isSiteMonthValueChanged = true;
        this._dataEntityService.hasDirtyCustomForm = true;
        const site = this.accountSites.find((a) => a.siteId == dialogSite.siteId);
        let waterBudgetSiteEntity = waterBudgetSiteEntities.find((a) => a.AssetId == dialogSite.siteId);
        site.isInWaterBudget = true;
        site.waterBudgetId = this.budget.id;
        site.systemEfficiency = this.budget.systemEfficiency;
        site.rainfallAdjustment = this.budget.usefulRainfall;
        site.desiredCropCoefficient = this.budgetDataSource.desiredCropCoefficient;
        site.monthList.forEach((siteMonth) => {
          const budgetMonth = this.budgetDataSource.monthList.find((a) => a.dayNumber == siteMonth.dayNumber);
          let waterNeeded = budgetMonth.plantWaterUse - (budgetMonth.expectedRainMonthlyValue * site.rainfallAdjustment) / 100;
          if (waterNeeded < 0) waterNeeded = 0;
          siteMonth.water_KL_Budget = (waterNeeded / (site.systemEfficiency / 100)) * site.irrigationArea * 10;
          siteMonth.water_KL_Budget_PerHa = siteMonth.water_KL_Budget / site.irrigationArea;
          if (siteMonth.water_KL_Override == null) {
            //site maybe just added
            siteMonth.water_KL_Override = siteMonth.water_KL_Budget;
            siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa;
          }
        });
        site.totalWaterKLBudget = site.monthList.reduce((sum, siteMonth) => sum + siteMonth.water_KL_Budget, 0);
        site.totalWaterKLBudgetPerHa = site.totalWaterKLBudget / site.irrigationArea;
        site.totalWaterKLOverride = site.monthList.reduce((sum, siteMonth) => sum + siteMonth.water_KL_Override, 0);
        site.totalWaterKLOverridePerHa = site.totalWaterKLOverride / site.irrigationArea;
        if (waterBudgetSiteEntity != null) {
          //update site with entity value
          waterBudgetSiteEntity.SystemEfficiencyPct = this.budget.systemEfficiency;
          waterBudgetSiteEntity.UsefulRainfallPct = this.budget.usefulRainfall;
          waterBudgetSiteEntity.BudgetId = this.budget.id;
        } else {
          waterBudgetSiteEntity = deletedWaterBudgetSiteEntities.find((a) => a.AssetId == dialogSite.siteId);
          if (waterBudgetSiteEntity != null) {
            //the site is deleted, recover from cache
            waterBudgetSiteEntity.BudgetId = this.budget.id;
            waterBudgetSiteEntity.SystemEfficiencyPct = this.budget.systemEfficiency;
            waterBudgetSiteEntity.UsefulRainfallPct = this.budget.usefulRainfall;
            waterBudgetSiteEntity.entityAspect.setModified();
          } else {
            //create waterBudgetSite entity
            const waterBudgetSiteType: any = this.entityManager.metadataStore.getEntityType('WaterBudgetSite');
            waterBudgetSiteEntity = waterBudgetSiteType.createEntity() as WaterBudgetSite;
            waterBudgetSiteEntity.AssetId = site.siteId;
            waterBudgetSiteEntity.BudgetId = this.budget.id;
            waterBudgetSiteEntity.SystemEfficiencyPct = this.budget.systemEfficiency;
            waterBudgetSiteEntity.UsefulRainfallPct = this.budget.usefulRainfall;
            this.entityManager.addEntity(waterBudgetSiteEntity);
          }
        }
      }
    });
    this.initWaterBudgetSiteMonthList();
    this.calculateWaterBudget();
    this.reloadCount++;
  }

  public openAddGroupToWaterBudgetDialog() {
    const dialogGroups = angular.copy(this.accountGroups);
    this._mdDialog
      .show({
        controller: AddGroupToWaterBudgetDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/addGroupToWaterBudget-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          dialogGroups: dialogGroups,
        },
      })
      .then((res) => {
        if (res) {
          const dialogGroups = res.dialogGroups as fuse.waterBudgetGroupDto[];
          this.addSitesInGroups(dialogGroups);
        }
      });
  }

  private addSitesInGroups(dialogGroups: fuse.waterBudgetGroupDto[]) {
    const waterBudgetSiteQuery = breeze.EntityQuery.from('WaterBudgetSite').toType('WaterBudgetSite');
    const waterBudgetSiteEntities = this.entityManager.executeQueryLocally(
      waterBudgetSiteQuery,
    ) as WaterBudgetSite[]; // query the cache (synchronous)
    const deletedWaterBudgetSiteEntities = this.entityManager.getEntities(
      'WaterBudgetSite',
      breeze.EntityState.Deleted,
    ) as WaterBudgetSite[];
    dialogGroups.forEach((dialogGroup) => {
      if (dialogGroup.isSelected && dialogGroup.groupSites != null) {
        dialogGroup.groupSites.forEach((dialogSite) => {
          const site = this.accountSites.find((a) => a.siteId == dialogSite.siteId);
          if (site.isInWaterBudget == false) {
            this.isBudgetChanged = true;
            this.isSiteMonthValueChanged = true;
            this._dataEntityService.hasDirtyCustomForm = true;
            let waterBudgetSiteEntity = waterBudgetSiteEntities.find((a) => a.AssetId == dialogSite.siteId);
            site.isInWaterBudget = true;
            site.waterBudgetId = this.budget.id;
            site.systemEfficiency = this.budget.systemEfficiency;
            site.rainfallAdjustment = this.budget.usefulRainfall;
            site.desiredCropCoefficient = this.budgetDataSource.desiredCropCoefficient;
            site.monthList.forEach((siteMonth) => {
              const budgetMonth = this.budgetDataSource.monthList.find((a) => a.dayNumber == siteMonth.dayNumber);
              let waterNeeded =
                budgetMonth.plantWaterUse - (budgetMonth.expectedRainMonthlyValue * site.rainfallAdjustment) / 100;
              if (waterNeeded < 0) waterNeeded = 0;
              siteMonth.water_KL_Budget = (waterNeeded / (site.systemEfficiency / 100)) * site.irrigationArea * 10;
              siteMonth.water_KL_Budget_PerHa = siteMonth.water_KL_Budget / site.irrigationArea;
              if (siteMonth.water_KL_Override == null) {
                //site maybe just added
                siteMonth.water_KL_Override = siteMonth.water_KL_Budget;
                siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa;
              }
            });
            site.totalWaterKLBudget = site.monthList.reduce((sum, siteMonth) => sum + siteMonth.water_KL_Budget, 0);
            site.totalWaterKLBudgetPerHa = site.totalWaterKLBudget / site.irrigationArea;
            site.totalWaterKLOverride = site.monthList.reduce((sum, siteMonth) => sum + siteMonth.water_KL_Override, 0);
            site.totalWaterKLOverridePerHa = site.totalWaterKLOverride / site.irrigationArea;
            if (waterBudgetSiteEntity != null) {
              //update site with entity value
              waterBudgetSiteEntity.SystemEfficiencyPct = this.budget.systemEfficiency;
              waterBudgetSiteEntity.UsefulRainfallPct = this.budget.usefulRainfall;
              waterBudgetSiteEntity.BudgetId = this.budget.id;
            } else {
              waterBudgetSiteEntity = deletedWaterBudgetSiteEntities.find((a) => a.AssetId == dialogSite.siteId);
              if (waterBudgetSiteEntity != null) {
                //the site is deleted, recover from cache
                waterBudgetSiteEntity.BudgetId = this.budget.id;
                waterBudgetSiteEntity.SystemEfficiencyPct = this.budget.systemEfficiency;
                waterBudgetSiteEntity.UsefulRainfallPct = this.budget.usefulRainfall;
                waterBudgetSiteEntity.entityAspect.setModified();
              } else {
                //create waterBudgetSite entity
                const waterBudgetSiteType: any = this.entityManager.metadataStore.getEntityType('WaterBudgetSite');
                waterBudgetSiteEntity = waterBudgetSiteType.createEntity() as WaterBudgetSite;
                waterBudgetSiteEntity.AssetId = site.siteId;
                waterBudgetSiteEntity.BudgetId = this.budget.id;
                waterBudgetSiteEntity.SystemEfficiencyPct = this.budget.systemEfficiency;
                waterBudgetSiteEntity.UsefulRainfallPct = this.budget.usefulRainfall;
                this.entityManager.addEntity(waterBudgetSiteEntity);
              }
            }
          }
        });
      }
    });
    this.initWaterBudgetSiteMonthList();
    this.calculateWaterBudget();
    this.reloadCount++;
  }

  private setControlsDirty() {
    angular.forEach(this.scope['budgetForm'], (field) => {
      if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
        field.$setDirty();
        field.$setTouched();
      }
    });
    angular.forEach(this.scope['summaryForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          field.$setDirty();
          field.$setTouched();
        }
      });
    });
    angular.forEach(this.scope['weatherForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          field.$setDirty();
          field.$setTouched();
        }
      });
    });
    angular.forEach(this.scope['sitesForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          field.$setDirty();
          field.$setTouched();
        }
      });
    });
    angular.forEach(this.scope['adjustmensForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          field.$setDirty();
          field.$setTouched();
        }
      });
    });
  }

  public rejectChanges(): void {
    //rejectChange only works for saved budget
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.WARNING'))
      .htmlContent(`<b>${this._languageService.instant('COMMON.CONFIRM_DISCARD')}</b>`)
      .multiple(true)
      .ok(this._languageService.instant('COMMON.YES'))
      .cancel(this._languageService.instant('COMMON.NO'));
    this._mdDialog.show(confirm).then(() => {
      this._dataEntityService.clear();
      this.budgetDataSource.monthList = [];
      this.accountSites.forEach((site) => {
        site.monthList = [];
      });

      const context = LocalStorageUtils.contextData;
      const budgetId = context.budgetId;
      const promises = [] as angular.IPromise<void>[];

      promises.push(this.fetchWaterBudget(budgetId));
      promises.push(this.fetchWaterBudgetSites(budgetId));
      promises.push(this.fetchWaterBudgetMonths(budgetId));
      promises.push(this.getWaterBudgetSiteMonths(budgetId));

      this._q.all(promises).then(() => {
        this.initialise();
        this.isBudgetChanged = false;
        this.isSiteMonthValueChanged = false;
        this._dataEntityService.hasDirtyCustomForm = false;

        if (this.waterBudgets.some((a) => a.id != this.budget.id && a.name == this.budget.name)) {
          this.isBudgetNameExist = true;
        } else {
          this.isBudgetNameExist = false;
        }
      });
    });
  }

  public changeBudget() {
    this.isBudgetChanged = true;
    this.isBudgetNameExist = false;
    if (this.budget.name != null) {
      if (this.waterBudgets.some((a) => a.id != this.budget.id && a.name == this.budget.name)) {
        this.isBudgetNameExist = true;
      }
    }
    let isMonthChanged = false;
    if (this.budget.startDate != this.startDate) {
      isMonthChanged = true;
    }
    if (this.budget.endDate != this.endDate) {
      if (this.endDate.getDate() == 1) {
        //set to last date of the month
        this.endDate.addMonths(1).addDays(-1);
        if (this.budget.endDate != this.endDate) {
          isMonthChanged = true;
        }
      }
    }
    const promises = [] as angular.IPromise<void>[];
    if (isMonthChanged) {
      promises.push(this.openChangeMonthConfirmDialog());
    }
    this._q.all(promises).then(() => {
      const waterBudgetQuery = breeze.EntityQuery.from('WaterBudget').toType('WaterBudget');
      const waterBudgetEntities = this.entityManager.executeQueryLocally(waterBudgetQuery) as WaterBudget[]; // query the cache (synchronous)
      const waterBudgetEntity = waterBudgetEntities.find((a) => a.Id == this.budget.id);
      waterBudgetEntity.Name = this.budget.name;
      waterBudgetEntity.Status = this.budget.status;
      waterBudgetEntity.StartDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.budget.startDate);
      waterBudgetEntity.EndDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.budget.endDate);
      waterBudgetEntity.DefaultKLPerHa = this.budget.defaultKLPerHa;
      waterBudgetEntity.DefaultSystemEfficiencyPct = this.budget.systemEfficiency;
      waterBudgetEntity.DefaultUsefulRainfallPct = this.budget.usefulRainfall;
    });
  }

  private openChangeMonthConfirmDialog(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.WARNING'))
      .htmlContent(this._languageService.instant('WATER.BUDGET.CONFIRM_MONTH_CHANGE'))
      .multiple(true)
      .ok(this._languageService.instant('COMMON.CONTINUE'))
      .cancel(this._languageService.instant('COMMON.NO_GO_BACK'));
    this._mdDialog.show(confirm).then(
      () => {
        this._mdDialog.hide();
        this.isBudgetChanged = true;
        this.isSiteMonthValueChanged = true;
        this._dataEntityService.hasDirtyCustomForm = true;
        this.budget.startDate = this.startDate;
        this.budget.startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.startDate);
        this.budget.endDate = this.endDate;
        this.budget.endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.endDate);
        this.maximumDate = this.budget.startDate.clone().addYears(1).addDays(-1);
        this.minimumDate = this.budget.endDate.clone().addYears(-1).addDays(1);
        this.clearWaterBudgetSiteMonthList();
        this.initBudgetDataSourceMonthList();
        this.initWaterBudgetSiteMonthList();
        this.updateBudgetCropSource();
        this.updateBudgetWeatherSourceBeau();
        this.calculateWaterBudget();
        defer.resolve();
      },
      () => {
        // user canceled the event
        this._mdDialog.hide();
        this.startDate = this.budget.startDate;
        this.endDate = this.budget.endDate;
        defer.resolve();
      },
    );
    return defer.promise;
  }

  private clearWaterBudgetSiteMonthList() {
    this.accountSites.forEach((site) => {
      site.monthList = [];
    });
  }

  private updateBudgetWeatherSourceBeau() {
    if (this.budget.weatherSource == 'W') {
      this.weatherStationStatics.forEach((weatherMonth) => {
        const budgetMonth = this.budgetDataSource.monthList.find((a) => a.monthValue == weatherMonth.month - 1);
        if (budgetMonth != null) {
          weatherMonth.daysInMonth = new Date(
            budgetMonth.dateTime.getFullYear(),
            budgetMonth.dateTime.getMonth() + 1,
            0,
          ).getDate();
          budgetMonth.historicalEtoDailyValue = weatherMonth.historicalEtoDailyValue;
          budgetMonth.lastYearEtoDailyValue = weatherMonth.lastYearEtoDailyValue;
          budgetMonth.lastYearEtoDailyPercent = Math.round(
            (budgetMonth.lastYearEtoDailyValue / budgetMonth.historicalEtoDailyValue) * 100,
          );
          budgetMonth.historicalRainMonthlyValue = weatherMonth.historicalRainMonthlyValue;
          budgetMonth.lastYearRainMonthlyValue = weatherMonth.lastYearRainMonthlyValue;
          budgetMonth.lastYearRainMonthlyPercent = Math.round(
            (budgetMonth.lastYearRainMonthlyValue / budgetMonth.historicalRainMonthlyValue) * 100,
          );
        } else {
          const localeDate = this._dayNumberService.convertDayNumberToDate(weatherMonth.dayNumber);
          weatherMonth.daysInMonth = new Date(localeDate.getFullYear(), localeDate.getMonth() + 1, 0).getDate();
        }
      });
      this.budgetDataSource.historicalEtoBudgetYearlyValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.daysInMonth * budgetMonth.historicalEtoDailyValue,
        0,
      );
      this.budgetDataSource.lastYearEtoBudgetValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.daysInMonth * budgetMonth.lastYearEtoDailyValue,
        0,
      );
      this.budgetDataSource.lastYearEtoBudgetPercent =
        (this.budgetDataSource.lastYearEtoBudgetValue * 100) / this.budgetDataSource.historicalEtoBudgetYearlyValue;

      this.budgetDataSource.historicalRainBudgetYearlyValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.historicalRainMonthlyValue,
        0,
      );
      this.budgetDataSource.lastYearRainBudgetValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.lastYearRainMonthlyValue,
        0,
      );
      this.budgetDataSource.lastYearRainBudgetPercent =
        (this.budgetDataSource.lastYearRainBudgetValue * 100) / this.budgetDataSource.historicalRainBudgetYearlyValue;
    } else if (this.budget.weatherSource == 'M') {
      this.budgetDataSource.monthList.forEach((budgetMonth) => {
        budgetMonth.historicalEtoDailyValue = budgetMonth.manualEto;
        budgetMonth.lastYearEtoDailyValue = budgetMonth.manualEto;
        budgetMonth.historicalRainMonthlyValue = budgetMonth.manualRain;
        budgetMonth.lastYearRainMonthlyValue = budgetMonth.manualRain;
      });
      this.budgetDataSource.historicalEtoBudgetYearlyValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.daysInMonth * budgetMonth.historicalEtoDailyValue,
        0,
      );
      this.budgetDataSource.lastYearEtoBudgetValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.daysInMonth * budgetMonth.lastYearEtoDailyValue,
        0,
      );

      this.budgetDataSource.historicalRainBudgetYearlyValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.historicalRainMonthlyValue,
        0,
      );
      this.budgetDataSource.lastYearRainBudgetValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.lastYearRainMonthlyValue,
        0,
      );
    } else if (this.budget.weatherSource == 'I') {
      this.budgetDataSource.monthList.forEach((budgetMonth) => {
        budgetMonth.historicalEtoDailyValue = 0;
        budgetMonth.lastYearEtoDailyValue = 0;
        budgetMonth.historicalRainMonthlyValue = 0;
        budgetMonth.lastYearRainMonthlyValue = 0;
      });
      this.budgetDataSource.historicalEtoBudgetYearlyValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.daysInMonth * budgetMonth.historicalEtoDailyValue,
        0,
      );
      this.budgetDataSource.lastYearEtoBudgetValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.daysInMonth * budgetMonth.lastYearEtoDailyValue,
        0,
      );

      this.budgetDataSource.historicalRainBudgetYearlyValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.historicalRainMonthlyValue,
        0,
      );
      this.budgetDataSource.lastYearRainBudgetValue = this.budgetDataSource.monthList.reduce(
        (sum, budgetMonth) => sum + budgetMonth.lastYearRainMonthlyValue,
        0,
      );
    }
    this.updateBudgetCropSource();
  }

  private updateBudgetCropSource() {
    if (this.budget.cropId != null) {
      const crop = this.accountCrops.find((a) => a.id == this.budget.cropId);
      if (crop != null) {
        if (this.budget.cropName != crop.name) {
          //crop is changed
          this.budget.cropName = crop.name;
          this.budgetDataSource.monthList.forEach((month) => {
            month.cropCoefficient = crop.dailyKCMonths[month.monthValue];
            month.desiredCropCoefficient = Math.round(month.cropCoefficient * 1000) / 1000;
            month.plantWaterUse = month.expectedEtoMonthlyValue * month.desiredCropCoefficient;
            const waterBudgetMonth = this.waterBudgetMonths.find((a) => a.dayNumber == month.dayNumber);
            waterBudgetMonth.TargetCropCoefficient = month.desiredCropCoefficient;
            const waterBudgetMonthQuery = breeze.EntityQuery.from('WaterBudgetMonth').toType('WaterBudgetMonth');
            const waterBudgetMonthEntities = this.entityManager.executeQueryLocally(
              waterBudgetMonthQuery,
            ) as WaterBudgetMonth[]; // query the cache (synchronous)
            const waterBudgetMonthEntity = waterBudgetMonthEntities.find((a) => a.Id == waterBudgetMonth.Id);
            waterBudgetMonthEntity.TargetCropCoefficient = waterBudgetMonth.TargetCropCoefficient;
          });
        } else {
          //it is a cloned budget, only run in initialise
          this.budgetDataSource.monthList.forEach((month) => {
            month.cropCoefficient = crop.dailyKCMonths[month.monthValue];
            const waterBudgetMonth = this.waterBudgetMonths.find((a) => a.dayNumber == month.dayNumber);
            month.desiredCropCoefficient = this.roundTo2dp(waterBudgetMonth.TargetCropCoefficient.toFixed(2));
          });
        }
      } else {
        //crop may have been removed or disabled
        this.budgetDataSource.monthList.forEach((month) => {
          const waterBudgetMonth = this.waterBudgetMonths.find((a) => a.dayNumber == month.dayNumber);
          month.desiredCropCoefficient = this.roundTo2dp(waterBudgetMonth.TargetCropCoefficient);
        });
      }
    }
  }

  public roundTo2dp(num) {
    return parseFloat(Number(num).toFixed(2));
  }

  public changeBudgetCrop() {
    this.isBudgetChanged = true;
    const waterBudgetQuery = breeze.EntityQuery.from('WaterBudget').toType('WaterBudget');
    const waterBudgetEntities = this.entityManager.executeQueryLocally(waterBudgetQuery) as WaterBudget[]; // query the cache (synchronous)
    const waterBudgetEntity = waterBudgetEntities.find((a) => a.Id == this.budget.id);
    waterBudgetEntity.CropId = this.budget.cropId;
    this.updateBudgetCropSource();
    this.calculateWaterBudget();
  }

  public changeBudgetMonthValue(month: fuse.waterBudgetMonthDto) {
    this.isBudgetChanged = true;

    const waterBudgetMonth = this.waterBudgetMonths.find((a) => a.dayNumber == month.dayNumber);

    waterBudgetMonth.ThisYearEToPct = month.expectedEtoDailyPercent;
    waterBudgetMonth.ThisYearRainPct = month.expectedRainMonthlyPercent;

    const waterBudgetMonthQuery = breeze.EntityQuery.from('WaterBudgetMonth').toType('WaterBudgetMonth');
    const waterBudgetMonthEntities = this.entityManager.executeQueryLocally(
      waterBudgetMonthQuery,
    ) as WaterBudgetMonth[]; // query the cache (synchronous)

    const waterBudgetMonthEntity = waterBudgetMonthEntities.find((a) => a.Id == waterBudgetMonth.Id);

    if (month.desiredCropCoefficient) {
      waterBudgetMonthEntity.TargetCropCoefficient = waterBudgetMonth.TargetCropCoefficient = month.desiredCropCoefficient;
    }

    waterBudgetMonthEntity.ThisYearEToPct = waterBudgetMonth.ThisYearEToPct;
    waterBudgetMonthEntity.ThisYearRainPct = waterBudgetMonth.ThisYearRainPct;

    this.calculateWaterBudget();
  }

  public openChangeWeatherStationDialog() {
    //if there is no site in the budget, use all sites
    let latitude: number;
    let longitude: number;
    if (this.accountSites.some((a) => a.isInWaterBudget)) {
      const budgetSites = this.accountSites.filter((a) => a.isInWaterBudget == true);
      latitude = budgetSites.reduce((sum, site) => sum + site.latitude, 0) / budgetSites.length;
      longitude = budgetSites.reduce((sum, site) => sum + site.longitude, 0) / budgetSites.length;
    } else {
      latitude = this.accountSites.reduce((sum, site) => sum + site.latitude, 0) / this.accountSites.length;
      longitude = this.accountSites.reduce((sum, site) => sum + site.longitude, 0) / this.accountSites.length;
    }
    this._mdDialog
      .show({
        controller: ChangeWeatherStationDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/changeWeatherStation-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          latitude: latitude,
          longitude: longitude,
          waterBudget: this.budget,
          waterBudgetDataSource: this.budgetDataSource,
        },
      })
      .then((returnData) => {
        if (returnData) {
          const waterBudget = returnData.waterBudget as fuse.waterBudgetDto;

          this.isBudgetChanged = true;
          this.budgetDataSource = returnData.waterBudgetDataSource as fuse.waterBudgetDataSourceDto;

          this.budget.etoStationId = waterBudget.etoStationId;
          this.budget.etoStationName = waterBudget.etoStationName;
          this.budget.rainStationId = waterBudget.rainStationId;
          this.budget.rainStationName = waterBudget.rainStationName;
          this.budget.weatherSource = waterBudget.weatherSource;

          this.waterBudgetMonths.forEach((budgetMonth) => {
            const sourceMonth = this.budgetDataSource.monthList.find((a) => a.dayNumber == budgetMonth.dayNumber);
            budgetMonth.ManualEto = sourceMonth.manualEto;
            budgetMonth.ManualRain = sourceMonth.manualRain;
          });

          const waterBudgetQuery = breeze.EntityQuery.from('WaterBudget').toType('WaterBudget');
          const waterBudgetEntities = this.entityManager.executeQueryLocally(waterBudgetQuery) as WaterBudget[]; // query the cache (synchronous)
          const waterBudgetEntity = waterBudgetEntities.find((a) => a.Id == this.budget.id);
          const promises = [] as angular.IPromise<void>[];
          waterBudgetEntity.WeatherSource = this.budget.weatherSource;
          if (
            waterBudgetEntity.EtoWeatherStationId != this.budget.etoStationId ||
            waterBudgetEntity.RainWeatherStationId != this.budget.rainStationId
          ) {
            waterBudgetEntity.EtoWeatherStationId = this.budget.etoStationId;
            waterBudgetEntity.RainWeatherStationId = this.budget.rainStationId;
            if (waterBudgetEntity.WeatherSource == 'W') {
              promises.push(this.getBudgetWeatherStatistics());
            }
          }
          this._q.all(promises).then(() => {
            this.updateBudgetWeatherSourceBeau();
            this.calculateWaterBudget();
          });
        }
      });
  }

  public removeSiteFromBudget(site: fuse.waterBudgetSiteDto) {
    let htmlContent = this._languageService.instant('WATER.BUDGET.REMOVE_SITE1', { site: site.name });
    if (this.isBudgetApplied) {
      htmlContent += '<br>' + this._languageService.instant('WATER.BUDGET.REMOVE_SITE2');
    }
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.CONFIRM'))
      .htmlContent(htmlContent)
      .parent(angular.element(document.body))
      .ok(this._languageService.instant('COMMON.CONTINUE'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));
    this._mdDialog.show(confirm).then(() => {
      this._mdDialog.hide();
      this.isBudgetChanged = true;
      this.isSiteMonthValueChanged = true;
      this._dataEntityService.hasDirtyCustomForm = true;
      site.isInWaterBudget = false;
      site.isSelected = false;
      site.monthList = [];
      const waterBudgetSiteQuery = breeze.EntityQuery.from('WaterBudgetSite').toType('WaterBudgetSite');
      const waterBudgetSiteEntities = this.entityManager.executeQueryLocally(
        waterBudgetSiteQuery,
      ) as WaterBudgetSite[]; // query the cache (synchronous)
      const waterBudgetSiteEntity = waterBudgetSiteEntities.find((a) => a.AssetId == site.siteId);
      waterBudgetSiteEntity.entityAspect.setDeleted();
      this.selectBudgetSiteChanged();
      this.initWaterBudgetSiteMonthList();
      this.calculateWaterBudget();
    });
  }

  public selectAllBudgetSites() {
    const budgetSites = this.accountSites.filter((a) => a.isInWaterBudget) as fuse.waterBudgetSiteDto[];
    if (this.isSelectAllBudgetSites) {
      budgetSites.forEach((site) => {
        site.isSelected = true;
      });
    } else {
      budgetSites.forEach((site) => {
        site.isSelected = false;
      });
    }
  }

  public selectBudgetSiteChanged() {
    const budgetSites = this.accountSites.filter((a) => a.isInWaterBudget) as fuse.waterBudgetSiteDto[];
    if (budgetSites?.some((a) => a.isSelected == false)) {
      this.isSelectAllBudgetSites = false;
    } else {
      this.isSelectAllBudgetSites = true;
    }
  }

  public isAnySiteSelected(): boolean {
    if (this.accountSites.some((a) => a.isInWaterBudget && a.isSelected)) return true;
    return false;
  }

  public openUpdateMultiSitesDialog() {
    this._mdDialog
      .show({
        controller: WaterBudgetUpdateMultiSitesDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/updateMultiSites-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then((returnData) => {
        if (returnData) {
          this.isBudgetChanged = true;
          const waterBudgetSiteSettings = returnData.waterBudgetSiteSettings as IWaterBudgetSiteSetting[];
          const selectedSites = this.accountSites.filter((a) => a.isInWaterBudget && a.isSelected);
          waterBudgetSiteSettings.forEach((siteSetting) => {
            if (siteSetting.checked) {
              selectedSites.forEach((site) => {
                if (siteSetting.key == WaterConstants.systemCoefficiencyKey) {
                  site.systemEfficiency = siteSetting.defaultNumberValue;
                }

                if (siteSetting.key == WaterConstants.usefulRainfallKey) {
                  site.rainfallAdjustment = siteSetting.defaultNumberValue;
                }
              });
            }
          });
          const waterBudgetSiteQuery = breeze.EntityQuery.from('WaterBudgetSite');
          const waterBudgetSiteEntities = this.entityManager.executeQueryLocally(
            waterBudgetSiteQuery,
          ) as WaterBudgetSite[]; // query the cache (synchronous)
          selectedSites.forEach((waterBudgetSite) => {
            const waterBudgetSiteEntity = waterBudgetSiteEntities.find((a) => a.AssetId == waterBudgetSite.siteId);
            waterBudgetSiteEntity.SystemEfficiencyPct = waterBudgetSite.systemEfficiency;
            waterBudgetSiteEntity.UsefulRainfallPct = waterBudgetSite.rainfallAdjustment;
          });
          this.calculateWaterBudget();
        }
      });
  }

  public removeMultiSites() {
    const selectSites = this.accountSites.filter((a) => a.isSelected);
    let htmlContent = this._languageService.instant('WATER.BUDGET.REMOVE_SITES1', { num: selectSites.length });
    if (this.isBudgetApplied) {
      htmlContent += '<br>' + this._languageService.instant('WATER.BUDGET.REMOVE_SITES2');
    }
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.CONFIRM'))
      .htmlContent(htmlContent)
      .parent(angular.element(document.body))
      .ok(this._languageService.instant('COMMON.CONFIRM'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));
    this._mdDialog.show(confirm).then(() => {
      this._mdDialog.hide();
      this.isBudgetChanged = true;
      this.isSiteMonthValueChanged = true;
      this._dataEntityService.hasDirtyCustomForm = true;
      selectSites.forEach((selectSite) => {
        const site = this.accountSites.find((a) => a.siteId == selectSite.siteId);
        site.isInWaterBudget = false;
        site.isSelected = false;
        site.monthList = [];
        const waterBudgetSiteQuery = breeze.EntityQuery.from('WaterBudgetSite').toType('WaterBudgetSite');
        const waterBudgetSiteEntities = this.entityManager.executeQueryLocally(
          waterBudgetSiteQuery,
        ) as WaterBudgetSite[]; // query the cache (synchronous)
        const waterBudgetSiteEntity = waterBudgetSiteEntities.find((a) => a.AssetId == site.siteId);
        waterBudgetSiteEntity.entityAspect.setDeleted();
      });
      this.selectBudgetSiteChanged();
      this.initWaterBudgetSiteMonthList();
      this.calculateWaterBudget();
    });
  }

  public changeBudgetDefaultValue() {
    this.isBudgetChanged = true;
    const waterBudgetQuery = breeze.EntityQuery.from('WaterBudget').toType('WaterBudget');
    const waterBudgetEntities = this.entityManager.executeQueryLocally(waterBudgetQuery) as WaterBudget[]; // query the cache (synchronous)
    const waterBudgetEntity = waterBudgetEntities.find((a) => a.Id == this.budget.id);
    waterBudgetEntity.DefaultKLPerHa = this.budget.defaultKLPerHa;
    waterBudgetEntity.DefaultSystemEfficiencyPct = this.budget.systemEfficiency;
    waterBudgetEntity.DefaultUsefulRainfallPct = this.budget.usefulRainfall;
    this.calculateWaterBudget();
  }

  public changeSiteSetting(site: fuse.waterBudgetSiteDto) {
    this.isBudgetChanged = true;
    this.isSiteMonthValueChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;
    this.updateWaterBudgetSiteEntity(site);
    this.calculateWaterBudget();
  }

  public changeSiteMonthValue(siteMonth: fuse.waterBudgetSiteMonthDto) {
    this.isSiteMonthValueChanged = true;
    this._dataEntityService.hasDirtyCustomForm = true;
    if (this.waterUnitType == 1) {
      siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Override / siteMonth.irrigationArea;
    } else {
      siteMonth.water_KL_Override = siteMonth.water_KL_Override_PerHa * siteMonth.irrigationArea;
    }
    this.calculateWaterBudget();
  }

  private updateWaterBudgetSiteEntity(site: fuse.waterBudgetSiteDto) {
    const waterBudgetSiteQuery = breeze.EntityQuery.from('WaterBudgetSite').toType('WaterBudgetSite');
    const waterBudgetSiteEntities = this.entityManager.executeQueryLocally(
      waterBudgetSiteQuery,
    ) as WaterBudgetSite[]; // query the cache (synchronous)
    const waterBudgetSiteEntity = waterBudgetSiteEntities.find((a) => a.AssetId == site.siteId);
    waterBudgetSiteEntity.SystemEfficiencyPct = site.systemEfficiency;
    waterBudgetSiteEntity.UsefulRainfallPct = site.rainfallAdjustment;
  }

  private calculateWaterBudget() {
    //if there is error in budgetDataSource, stop
    this.budgetDataSource.monthList.forEach((month) => {
      if (this.budget.weatherSource == 'W') {
        month.expectedEtoDailyValue = (month.historicalEtoDailyValue * month.expectedEtoDailyPercent) / 100;
        month.expectedEtoMonthlyValue = month.expectedEtoDailyValue * month.daysInMonth;
        month.expectedRainMonthlyValue = (month.historicalRainMonthlyValue * month.expectedRainMonthlyPercent) / 100;
      } else if (this.budget.weatherSource == 'M') {
        month.expectedEtoDailyValue = month.manualEto;
        month.expectedEtoMonthlyValue = month.expectedEtoDailyValue * month.daysInMonth;
        month.expectedRainMonthlyValue = month.manualRain;
      } else if (this.budget.weatherSource == 'I') {
        month.expectedEtoDailyValue = 0;
        month.expectedEtoMonthlyValue = 0;
        month.expectedRainMonthlyValue = 0;
      }
      month.plantWaterUse = month.desiredCropCoefficient * month.expectedEtoMonthlyValue;
      month.cumulativeWaterKLBudget = 0;
      month.cumulativeWaterKLOverride = 0;
    });

    this.budgetDataSource.totalPlantWaterUse = this.budgetDataSource.monthList.reduce(
      (sum, month) => sum + month.plantWaterUse, 0
    );

    this.budgetDataSource.totalIrrigationArea = this.accountSites
      .filter((a) => a.isInWaterBudget)
      .reduce((sum, site) => sum + site.irrigationArea, 0);

    this.budgetDataSource.expectedEtoBudgetValue =
      this.budgetDataSource.monthList.reduce((sum, month) => sum + month.expectedEtoMonthlyValue, 0);

    this.budgetDataSource.averageExpectedEtoDailyPercent =
      (this.budgetDataSource.expectedEtoBudgetValue / this.budgetDataSource.historicalEtoBudgetYearlyValue) * 100;

    this.budgetDataSource.expectedRainBudgetValue =
      this.budgetDataSource.monthList.reduce((sum, month) => sum + month.expectedRainMonthlyValue, 0);

    this.budgetDataSource.averageExpectedRainPercent =
      (this.budgetDataSource.expectedRainBudgetValue / this.budgetDataSource.historicalRainBudgetYearlyValue) * 100;

    this.budgetDataSource.desiredPlantWaterUse =
      this.budgetDataSource.monthList.reduce((sum, month) => sum + month.plantWaterUse, 0);

    this.budgetDataSource.desiredCropCoefficient =
      this.budgetDataSource.desiredPlantWaterUse / this.budgetDataSource.expectedEtoBudgetValue;

    //if there is error in site setting, will stop
    this.accountSites.forEach((site) => {
      if (site.isInWaterBudget) {
        site.monthList.forEach((siteMonth) => {
          const budgetMonth = this.budgetDataSource.monthList.find((a) => a.dayNumber == siteMonth.dayNumber);
          let waterNeeded = budgetMonth.plantWaterUse - (budgetMonth.expectedRainMonthlyValue * site.rainfallAdjustment) / 100;
          if (waterNeeded < 0) waterNeeded = 0;
          siteMonth.water_KL_Budget = (waterNeeded / (site.systemEfficiency / 100)) * site.irrigationArea * 10;
          siteMonth.water_KL_Budget_PerHa = siteMonth.water_KL_Budget / site.irrigationArea;
          if (siteMonth.water_KL_Override == null) {
            //site maybe just added
            siteMonth.water_KL_Override = siteMonth.water_KL_Budget;
            siteMonth.water_KL_Override_PerHa = siteMonth.water_KL_Budget_PerHa;
          }
        });
        site.desiredCropCoefficient = this.budgetDataSource.desiredCropCoefficient;
        site.totalWaterKLBudget = site.monthList.reduce((sum, siteMonth) => sum + siteMonth.water_KL_Budget, 0);
        site.totalWaterKLBudgetPerHa = site.totalWaterKLBudget / site.irrigationArea;
        site.totalWaterKLOverride = site.monthList.reduce((sum, siteMonth) => sum + siteMonth.water_KL_Override, 0);
        site.totalWaterKLOverridePerHa = site.totalWaterKLOverride / site.irrigationArea;
        const siteEfficiency = site.systemEfficiency / 100;
        const budgetWaterPct =
          (this.budgetDataSource.desiredPlantWaterUse -
            ((site.totalWaterKLBudgetPerHa / 1000) * siteEfficiency * 100 -
              (site.totalWaterKLOverridePerHa / 1000) * siteEfficiency * 100)) /
          this.budgetDataSource.expectedEtoBudgetValue;
        site.budgetCropCoefficient = Math.min(site.desiredCropCoefficient, budgetWaterPct);
        site.budgetKcWeighting = site.irrigationArea * site.budgetCropCoefficient;
        site.systemEfficiencyWeighting = (site.systemEfficiency * site.irrigationArea) / 100;
        site.rainfallWeighting = (site.rainfallAdjustment * site.irrigationArea) / 100;
      }
    });

    const budgetSites = this.accountSites.filter((a) => a.isInWaterBudget);
    this.budgetDataSource.averageSystemEfficiency =
      budgetSites.reduce((sum, site) => sum + site.systemEfficiencyWeighting, 0) / this.budgetDataSource.totalIrrigationArea;
    this.budgetDataSource.budgetDerivedCropCoefficient =
      budgetSites.reduce((sum, site) => sum + site.budgetKcWeighting, 0) / this.budgetDataSource.totalIrrigationArea;
    this.budgetDataSource.budgetPlantWaterUse =
      this.budgetDataSource.expectedEtoBudgetValue * this.budgetDataSource.budgetDerivedCropCoefficient;
    this.budgetDataSource.totalWaterKLBudget = budgetSites.reduce((sum, site) => sum + site.totalWaterKLBudget, 0);
    this.budgetDataSource.totalWaterKLBudgetPerHa =
      this.budgetDataSource.totalWaterKLBudget / this.budgetDataSource.totalIrrigationArea;
    this.budgetDataSource.totalWaterKLOverride = budgetSites.reduce((sum, site) => sum + site.totalWaterKLOverride, 0);
    this.budgetDataSource.totalWaterKLOverridePerHa =
      this.budgetDataSource.totalWaterKLOverride / this.budgetDataSource.totalIrrigationArea;
    this.budgetDataSource.desiredNetAllocationWater =
      (this.budgetDataSource.totalWaterKLBudgetPerHa / 1000) * 100 * this.budgetDataSource.averageSystemEfficiency;
    this.budgetDataSource.budgetNetAllocationWater =
      (this.budgetDataSource.totalWaterKLOverridePerHa / 1000) * 100 * this.budgetDataSource.averageSystemEfficiency;
    this.budgetDataSource.totalWaterAllocation = this.budget.defaultKLPerHa * this.budgetDataSource.totalIrrigationArea; //only used in chart
    let cumulativeWaterKLBudget = 0;
    let cumulativeWaterKLOverride = 0;
    this.budgetDataSource.monthList.forEach((sourceMonth) => {
      sourceMonth.totalWaterAllocation = this.budgetDataSource.totalWaterAllocation;
      sourceMonth.totalWaterAllocationPerHa = sourceMonth.totalWaterAllocation / this.budgetDataSource.totalIrrigationArea;
      sourceMonth.waterKLBudget = 0;
      sourceMonth.waterKLOverride = 0;
      budgetSites.forEach((site) => {
        const siteMonth = site.monthList.find((a) => a.dayNumber == sourceMonth.dayNumber);
        sourceMonth.waterKLBudget += siteMonth.water_KL_Budget;
        sourceMonth.waterKLOverride += siteMonth.water_KL_Override;
      });
      sourceMonth.waterKLBudgetPerHa = sourceMonth.waterKLBudget / this.budgetDataSource.totalIrrigationArea;
      sourceMonth.waterKLOverridePerHa = sourceMonth.waterKLOverride / this.budgetDataSource.totalIrrigationArea;
      cumulativeWaterKLBudget += sourceMonth.waterKLBudget;
      cumulativeWaterKLOverride += sourceMonth.waterKLOverride;
      sourceMonth.cumulativeWaterKLBudget = cumulativeWaterKLBudget;
      sourceMonth.cumulativeWaterKLBudgetPerHa = cumulativeWaterKLBudget / this.budgetDataSource.totalIrrigationArea;
      sourceMonth.cumulativeWaterKLOverride = cumulativeWaterKLOverride;
      sourceMonth.cumulativeWaterKLOverridePerHa = cumulativeWaterKLOverride / this.budgetDataSource.totalIrrigationArea;
      sourceMonth.monthCapWaterPerHa =
        sourceMonth.monthCapWater == null ? null : sourceMonth.monthCapWater / this.budgetDataSource.totalIrrigationArea;
      let derivedKc =
        (sourceMonth.plantWaterUse -
          (((sourceMonth.waterKLBudget - sourceMonth.waterKLOverride) / 1000) *
            this.budgetDataSource.averageSystemEfficiency *
            100) /
            this.budgetDataSource.totalIrrigationArea) /
        sourceMonth.expectedEtoMonthlyValue;
      if (derivedKc < 0) derivedKc = 0;
      sourceMonth.derivedCropCoefficient = Math.min(sourceMonth.desiredCropCoefficient, derivedKc);
    });
    this.budgetDataSource.rainfallUsedByPlant =
      this.budgetDataSource.desiredPlantWaterUse - this.budgetDataSource.desiredNetAllocationWater;
    this.budgetDataSource.plantWaterUseReduction =
      this.budgetDataSource.desiredPlantWaterUse - this.budgetDataSource.budgetPlantWaterUse;
    this.budgetDataSource.plantWaterUseReductionPercent =
      (this.budgetDataSource.plantWaterUseReduction / this.budgetDataSource.desiredPlantWaterUse) * 100;
    this.reloadCount++;
  }

  private saveWaterBudgetSiteMonths(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const waterBudgetSiteMonths = [] as fuse.waterBudgetSiteMonthDto[];
    const filteredSites = this.accountSites.filter((a) => a.isInWaterBudget);
    filteredSites.forEach((site) => {
      site.monthList.forEach((siteMonth) => {
        if (siteMonth.budgetId < 0) {
          //for new clone budget
          siteMonth.budgetId = this.budget.id;
        }
        waterBudgetSiteMonths.push(siteMonth);
      });
    });
    const body = { budgetId: this.budget.id, waterBudgetSiteMonthsDto: waterBudgetSiteMonths };
    this._http.post(CommonHelper.getApiUrl('water/SaveWaterBudgetSiteMonths'), JSON.stringify(body)).then(
      (response) => {
        defer.resolve();
      },
      (error) => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  public saveChanges(postSaveActions: PostSaveActions = null): void {
    let isValidChanges = true;
    //the budget form
    angular.forEach(this.scope['budgetForm'], (field) => {
      if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
        if (field.$invalid) isValidChanges = false;
      }
    });
    if (this.isBudgetNameExist) {
      isValidChanges = false;
    }
    //summary form
    angular.forEach(this.scope['summaryForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          if (field.$invalid) {
            isValidChanges = false;
            this.summaryTab = WaterBudgetSummaryTabEnums.Setting;
          }
        }
      });
    });
    if (this.budget.weatherSource == 'W' && (this.budget.etoStationId == null || this.budget.rainStationId == null)) {
      isValidChanges = false;
      this.summaryTab = WaterBudgetSummaryTabEnums.Setting;
    }
    //weather source
    angular.forEach(this.scope['weatherForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          if (field.$invalid) {
            isValidChanges = false;
            this.primaryTab = WaterBudgetPrimaryTabEnums.Weather;
          }
        }
      });
    });
    //sites tab
    angular.forEach(this.scope['sitesForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          if (field.$invalid) {
            isValidChanges = false;
            this.primaryTab = WaterBudgetPrimaryTabEnums.Sites;
          }
        }
      });
    });
    //adjustment tab
    angular.forEach(this.scope['adjustmentsForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          if (field.$invalid) {
            isValidChanges = false;
            this.primaryTab = WaterBudgetPrimaryTabEnums.Adjustments;
          }
        }
      });
    });
    // month list
    this.budgetDataSource.monthList.forEach((month) => {
      if (month.monthCapWater === undefined || month.monthCapWaterPerHa === undefined) {
        isValidChanges = false;
      }
    });
    if (isValidChanges == false) {
      const alert = this._languageService.fixErrorsDialog();
      this._mdDialog.show(alert).then(() => {
        this._mdDialog.hide();
        this._timeout(() => {
          this.setControlsDirty();
        }, 100);
      });
      return;
    } else {
      const promises = [] as angular.IPromise<breeze.SaveResult>[];
      //saveWaterBudgetSiteMonth can't run with saveChanges. for new/cloned budget, it need budget id.
      if (this.isBudgetChanged) {
        promises.push(this._dataEntityService.saveChanges() as angular.IPromise<any>);
      }
      this._q.all(promises).then(
        (saveResult) => {
          this.isBudgetChanged = false;
          //for new or cloned budget, need update budget
          if (this.budget.id < 0) {
            const budgetEntity = saveResult[0].entities.find(
              (a) => a.entityAspect.getKey().entityType.shortName == 'WaterBudget',
            ) as WaterBudget;
            this.budget.id = budgetEntity.Id;
          }
          const httpPromises = [] as angular.IPromise<void>[];
          if (this.isSiteMonthValueChanged) {
            httpPromises.push(this.saveWaterBudgetSiteMonths());
          }
          if (this.isNewBudget) {
            httpPromises.push(this.getAccountWaterBudgets());
            this.isNewBudget = false;
          }

          this._q.all(httpPromises).then(
            () => {
              this.isSiteMonthValueChanged = false;
              this._dataEntityService.hasDirtyCustomForm = false;
              this._languageService.showSaveSuccess();

              if (super.executeAnyPostSaveActions(postSaveActions)) {
                return;
              }

              if (this.originalStatus == WaterConstants.activeState && this.budget.status == WaterConstants.archivedState) {
                this.gotoWaterBudgets();
              }

              this.originalStatus = this.budget.status;
            },
            (error) => {
              this._languageService.whoops();
            },
          );
        },
        (error) => {
          this._languageService.whoops();
        },
      );
    }
  }

  public openChangeSiteTotalIrrigationDialog(site: fuse.waterBudgetSiteDto) {
    const dialogSite = angular.copy(site);
    this._mdDialog
      .show({
        controller: ChangeSiteTotalIrrigationDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/changeSiteTotalIrrigation-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          dialogSite: dialogSite,
          waterUnitType: this.waterUnitType,
        },
      })
      .then((returnData) => {
        if (returnData) {
          this.isSiteMonthValueChanged = true;
          this._dataEntityService.hasDirtyCustomForm = true;
          const dialogSite = returnData.dialogSite as fuse.waterBudgetSiteDto;
          const siteIndex = this.accountSites.findIndex((a) => a.siteId == dialogSite.siteId);
          this.accountSites[siteIndex] = dialogSite;
          this.calculateWaterBudget();
          this.reloadCount++;
        }
      });
  }

  public openChangeMonthTotalIrrigationDialog(month: fuse.waterBudgetMonthDto) {
    const dialogSites = this.accountSites.filter((a) => a.isInWaterBudget);
    const budgetMonth = angular.copy(month);
    this._mdDialog
      .show({
        controller: ChangeMonthTotalIrrigationDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/changeMonthTotalIrrigation-dialog.html',
        parent: angular.element(document.body),
        multiple: true,
        clickOutsideToClose: false,
        locals: {
          dialogSites: dialogSites,
          waterUnitType: this.waterUnitType,
          budgetMonth: budgetMonth,
        },
      })
      .then((returnData) => {
        if (returnData) {
          this.isSiteMonthValueChanged = true;
          this._dataEntityService.hasDirtyCustomForm = true;
          const dialogSites = returnData.dialogSites as fuse.waterBudgetSiteDto[];
          dialogSites.forEach((dialogSite) => {
            const siteIndex = this.accountSites.findIndex((a) => a.siteId == dialogSite.siteId);
            this.accountSites[siteIndex] = dialogSite;
          });
          this.calculateWaterBudget();
          this.reloadCount++;
        }
      });
  }

  public openChangeBudgetTotalIrrigationDialog() {
    const dialogSites = this.accountSites.filter((a) => a.isInWaterBudget);
    const budget = angular.copy(this.budgetDataSource);
    this._mdDialog
      .show({
        controller: ChangeBudgetTotalIrrigationDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/water/waterbudget/changeBudgetTotalIrrigation-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          dialogSites: dialogSites,
          waterUnitType: this.waterUnitType,
          budget: budget,
        },
      })
      .then((returnData) => {
        if (returnData) {
          this.isSiteMonthValueChanged = true;
          this._dataEntityService.hasDirtyCustomForm = true;
          const dialogSites = returnData.dialogSites as fuse.waterBudgetSiteDto[];
          dialogSites.forEach((dialogSite) => {
            const siteIndex = this.accountSites.findIndex((a) => a.siteId == dialogSite.siteId);
            this.accountSites[siteIndex] = dialogSite;
          });
          this.calculateWaterBudget();
          this.reloadCount++;
        }
      });
  }

  public openApplyBudgetToSitesDialog() {
    if (this.isBudgetChanged || this.isSiteMonthValueChanged) {
      const alert = this._mdDialog
        .alert()
        .title(this._languageService.instant('COMMON.ALERT'))
        .htmlContent(this._languageService.instant('COMMON.SAVE_CHANGES_FIRST'))
        .parent(angular.element(document.body))
        .ok(this._languageService.instant('COMMON.OK'))
        .targetEvent();
      this._mdDialog.show(alert).then(() => {
        this._mdDialog.hide();
      });
      return;
    } else {
      const waterBudgetWarnings = angular.element(document.getElementsByClassName('waterbudget-warning'));
      const isAnyWarning = !!waterBudgetWarnings.length;
      this._mdDialog
        .show({
          controller: ApplyBudgetToSitesDialogController,
          controllerAs: 'vm',
          templateUrl: 'src/app/pages/water/waterbudget/applyBudgetToSites-dialog.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            isAnyWarning: isAnyWarning,
          },
        })
        .then((returnData) => {
          if (returnData) {
            this.applyBudgetToSites(returnData.isCreateSitePlannedApplication).then(
              () => {
                this.isBudgetApplied = true;
                this._languageService.success('WATER.BUDGET.APPLIED');
              },
              (error) => {
                this._languageService.whoops();
              },
            );
          }
        });
    }
  }

  private applyBudgetToSites(isCreateSitePlannedApplication: boolean): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const applySiteMonths = [] as fuse.applySiteMonthDto[];
    const filteredSites = this.accountSites.filter((a) => a.isInWaterBudget);
    filteredSites.forEach((site) => {
      site.monthList.forEach((siteMonth) => {
        const sourceMonth = this.budgetDataSource.monthList.find((a) => a.dayNumber == siteMonth.dayNumber);
        const applySiteMonth = {
          budgetId: this.budget.id,
          siteId: siteMonth.siteId,
          water_KL: siteMonth.water_KL_Override,
          dayNumber: siteMonth.dayNumber,
          endDayNumber: siteMonth.dayNumber + sourceMonth.daysInMonth - 1,
        } as fuse.applySiteMonthDto;
        applySiteMonths.push(applySiteMonth);
      });
    });
    const body = {
      budgetId: this.budget.id,
      isCreateSitePlannedApplication: isCreateSitePlannedApplication,
      applySiteMonths: applySiteMonths,
    } as fuse.applyBudgetToSitesDto;
    this._http.post(CommonHelper.getApiUrl('water/ApplyWaterBudgetToSites'), JSON.stringify(body)).then(
      () => {
        defer.resolve();
      },
      (error) => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  public clickWeatherSourceTab() {
    //sometimes when change weather source tab, the chart is disappeared
    this.reloadCount++;
  }

  public isDataSourceReady(): boolean {
    if (this.budget.cropId == null) {
      return false;
    }
    if (this.budget.weatherSource == 'M' || this.budget.weatherSource == 'I') {
      return true;
    }
    if (this.budget.weatherSource == 'W' && this.budget.etoStationId != null && this.budget.rainStationId != null) {
      return true;
    } else {
      return false;
    }
  }

  public isWaterBudgetValid(): boolean {
    if (this.budget.systemEfficiency != null && this.budget.usefulRainfall != null) {
      return true;
    } else {
      return false;
    }
  }

  public siteAlertTooltip(desiredValue: number, budgetedValue: number): string {
    if (desiredValue == null || isNaN(desiredValue) || desiredValue == 0 || budgetedValue == null || isNaN(budgetedValue)) {
      return '';
    } else if (budgetedValue / desiredValue <= 0.9) {
      return this._languageService.instant('WATER.BUDGET.MUCH_TOO_LOW');
    } else if (budgetedValue / desiredValue <= 0.99) {
      return this._languageService.instant('WATER.BUDGET.TOO_LOW');
    } else {
      return '';
    }
  }

  public isAnySiteInWarning(): boolean {
    let result = false;
    angular.forEach(this.scope['sitesForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          if (field.$invalid) result = true;
        }
      });
    });
    //adjustment tab
    angular.forEach(this.scope['adjustmentsForm'], (childForm) => {
      angular.forEach(childForm, (field) => {
        if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
          if (field.$invalid) result = true;
        }
      });
    });
    return result;
  }

  public openWaterBudgetHelpDialog(key: string) {
    this._mdDialog.show({
      controller: WaterBudgetHelpDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/water/waterbudget/waterBudgetHelp-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      multiple: true,
      locals: {
        key: key,
      },
    });
  }

  public isWeatherSourceIgnored(): boolean {
    if (this.budget.weatherSource == 'I') {
      this.isCropRequiredHidden = true;
      return true;
    }
    return false;
  }

  public onMonthListChanged(monthList: fuse.waterBudgetMonthDto[]): void {
    this.isBudgetChanged = true;
    monthList.forEach((month) => {
      const waterBudgetMonth = this.waterBudgetMonths.find((a) => a.dayNumber == month.dayNumber);
      waterBudgetMonth.VolumeCapKL = month.monthCapWater;
      const waterBudgetMonthQuery = breeze.EntityQuery.from('WaterBudgetMonth').toType('WaterBudgetMonth');
      const waterBudgetMonthEntities = this.entityManager.executeQueryLocally(
        waterBudgetMonthQuery,
      ) as WaterBudgetMonth[]; // query the cache (synchronous)
      const waterBudgetMonthEntity = waterBudgetMonthEntities.find((a) => a.Id == waterBudgetMonth.Id);
      waterBudgetMonthEntity.VolumeCapKL = waterBudgetMonth.VolumeCapKL;
    });
  }

  public textConditionColor(desiredValue: number, budgetedValue: number): string {
    if (desiredValue == null || isNaN(desiredValue) || desiredValue == 0) return null;
    else if (budgetedValue / desiredValue <= 0.9) return 'red';
    else if (budgetedValue / desiredValue < 0.99) return 'orange';
    else return 'rgba(0, 0, 0, 0.7)';
  }

  public alertConditionDisplay(desiredValue: number, budgetedValue: number): boolean {
    if (desiredValue == null || isNaN(desiredValue) || desiredValue == 0 || budgetedValue == null || isNaN(budgetedValue))
      return false;
    else if (budgetedValue / desiredValue <= 0.9) return true;
    else if (budgetedValue / desiredValue < 0.99) return true;
    else if (budgetedValue / desiredValue < 1.05) return false;
    else return true;
  }
}

angular.module('app.water').controller('WaterBudgetController', WaterBudgetController);
