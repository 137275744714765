import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { unitSizes } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class WaterBudgetHelpDialogController extends BaseController {
  public key: string;
  public volumeLargeUnit: uomUnit;
  public volumeAreaLargeUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    key: string,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.key = key;
    this.volumeLargeUnit = UnitOfMeasureService.getUnits('Volume', unitSizes.large);
    this.volumeAreaLargeUnit = UnitOfMeasureService.getUnits('Volume/Area', unitSizes.large);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.water').controller('WaterBudgetHelpDialogController', WaterBudgetHelpDialogController);
