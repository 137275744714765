import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class ApplyBudgetToSitesDialogController extends BaseController {
  public isCreateSitePlannedApplication: boolean;
  public isAnyWarning: boolean;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    isAnyWarning: boolean,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.isAnyWarning = isAnyWarning;
  }

  public submit() {
    this._mdDialog.hide({ isCreateSitePlannedApplication: this.isCreateSitePlannedApplication });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.water').controller('ApplyBudgetToSitesDialogController', ApplyBudgetToSitesDialogController);
