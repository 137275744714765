// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#waterbudgetid > .header {
  height: 100px;
  min-height: 60px;
  max-height: 80px;
}
#waterbudgetid .content-container {
  padding: 24px;
  margin: 0;
}
#waterbudgetid .form-data-wrapper {
  margin-left: 16px;
}
#waterbudgetid .form-data-wrapper .json-data-wrapper {
  overflow: hidden;
}
#waterbudgetid .form-data-wrapper .json-data-wrapper pre {
  font-size: 16px;
}
#waterbudgetid .inputPaddingBottom {
  padding-bottom: 24px;
}
#waterbudgetid .inputPaddingTopBottom {
  padding-bottom: 24px;
  padding-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/water.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAGE;EACE,aAAA;EACA,SAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAII;EACE,gBAAA;AAFN;AAIM;EACE,eAAA;AAFR;AAOE;EACE,oBAAA;AALJ;AAQE;EACE,oBAAA;EACA,iBAAA;AANJ","sourcesContent":["#waterbudgetid {\r\n  > .header {\r\n    height: 100px;\r\n    min-height: 60px;\r\n    max-height: 80px;\r\n  }\r\n\r\n  .content-container {\r\n    padding: 24px;\r\n    margin: 0;\r\n  }\r\n\r\n  .form-data-wrapper {\r\n    margin-left: 16px;\r\n\r\n    .json-data-wrapper {\r\n      overflow: hidden;\r\n\r\n      pre {\r\n        font-size: 16px;\r\n      }\r\n    }\r\n  }\r\n\r\n  .inputPaddingBottom {\r\n    padding-bottom: 24px;\r\n  }\r\n\r\n  .inputPaddingTopBottom {\r\n    padding-bottom: 24px;\r\n    padding-top: 24px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
