// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#waterbudget-update-multi-sites md-checkbox {
  margin-bottom: 0;
}
#waterbudget-update-multi-sites md-input-container {
  margin: 0 !important;
  padding: 0;
  vertical-align: bottom;
}
#waterbudget-update-multi-sites md-input-container .md-errors-spacer {
  min-height: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/water/waterbudget/updateMultiSites-dialog.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAEI;EACI,oBAAA;EACA,UAAA;EACA,sBAAA;AAAR;AACQ;EAEI,0BAAA;AAAZ","sourcesContent":["#waterbudget-update-multi-sites {\r\n    md-checkbox {\r\n        margin-bottom: 0;\r\n    }\r\n    md-input-container {\r\n        margin: 0 !important;\r\n        padding: 0;\r\n        vertical-align: bottom;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
